import store from '../store/index'

export const events = {
    onClick: 'onclick',
    onChange: 'onchange',
    onLoad: 'onload',
    onSubmit: 'onsubmit',
}

export const actions = {
    changeContract: 'change contract',
    changeLanguage: 'change language',
    goToDashboard: 'go to dashboard/home',
    goToMyBenefits: 'go to my benefits',
    goToMyReservations: 'go to my reservations',
    goToMyProperty: 'go to my property',
    goToMyReports: 'go to my reports',
    goToMyAccount: 'go to my account',
    goToMyDocuments: 'go to documents',
    goToTheFivesVacations: 'go to the fives vacations',
    goToExchanges: 'got to exchanges',
    goToEditMyReservation: 'go to edit booking',

    changeLanguage: 'change language',
    //benefits
    getBenefitsByContract: 'get benefits by contract',
    getDetailsTransactionsBenefit: 'get details transaction benefit',

    closeTransacctionAgreementModal: 'close transacction agreement modal',
    openTransacctionAgreementModal: 'open transacction agreement modal',
    selectOption: 'select resolution from transacction agreement modal ',

    //login
    makeLogin: 'login',
    searchHousing: 'housing search',
    selectOwnerHousing: 'select owner housing',

    // reservations
    resetForm: 'reset form',
    requestReservation: 'request reservation',
    searchRoomsFailed: 'search rooms failed',
    requestReservationSuccessfull: 'request a reservation successfull',
    requestUpdateBooking: 'request update booking',
    requestUpdateBookingFailed: 'request update booking failed',
    requestUpdateBookingSuccessfull: 'request update booking successfull',
    openRsvCancellationRequest: 'open reservation cancellation request',
    requestReservationCancellationHasBeenSent: 'request for reservation cancellation has been sent',
    showCouponRsv: 'show coupon booking',


    //documents
    selectCategoryFile: 'select file type category',
    downloadFile: 'download file',
    //profile
    changePassword: 'change password',
    changePhotoProfile: 'change photo profile',
    updatePhotoProfile: 'update photo profile',
    uploadPhotoGallery: 'upload photo gallery',
    deleteTemporalPhotoGallery: 'delete photo gallery no saved',
    deletePhotoGallery: 'delete photo gallery',
    savePhotosGallery: 'save photos gallery',
    // tabs
    clickTabOption: 'select tab option',
    //reports
    signReport: 'sign report',
    intentToSign: 'sign attemp',
    //share social promos
    sharePromoFacebook: 'sharepromofacebook',
    sharePromoTwitter: 'sharepromotwitter',
    sharePromoLinkedIn: 'sharepromotlinkedin',
    sharePromoLink: 'sharepromolink',
    sharePromoLink: 'sharepromolink',
    sharePromoLink: 'sharepromolink',
    acceptToSharePromotions: "accept to share promotions",
    acceptToSharePromotionsWasSuccessfull: "accept to share promotions successfully",
    declineToSharePromotions: "decline to share promotions",

    // control de accesos
    accessControlLogWasCreated: "access control register was created",
    accessControlLogWasUpdated: "access control register was updated",
    accessControlLogWasDeleted: "access control register was deleted",

    // Control de acceso
    goToMyPropertyCreateControlRegister: 'go to create access control register',
    goToMyPropertyEditControlRegister: 'go to update access control register',

    // Solicitud de mantenimiento
    goToMyPropertyCreateMaintenanceRequest: "go to my property create maintenance request",
    attemptToCreateaMaintenanceRequest: "attempt to create a maintenance request",
    maintenanceRequestCreationRecord: "maintenance request creation successfully",
    goToShowMaintenanceDataRequest: "go to show maintenance request record",
    goToEditMaintenanceDataRequest: "go to edit maintenance request record",
    goToDeleteMaintenanceDataRequest: "delete/cancel a maintenance request",
    updateMaintenanceDataRequest: "update a maintenance request",

    // Exchanges
    goToExchanges: "go to my property create maintenance request",
    openModalExchanges: 'open exchanges terms and use modal',
    declineModalExchanges: "close exchanges terms and use modal",
    subscribeToExchanges: "Subscribe to Exchanges",

    closeTransacctionAgreementModal: 'close transacction agreement modal',
}

export const components = {
    changeContract: 'TopCards, card change contract',
    changeLanguage: 'change language dropdown',
    goToMyBenefitsFromDashboard: 'button go to my benefits from dashboard',
    goToMyReportsFromDashboard: 'button go to my reports from dashboard',
    goToMyAccountFromTopCard: 'TopCards, avatar of top card',
    goToDashboard: 'navigation link',
    goToMyBenefits: 'navigation link',
    goToMyReservations: 'navigation link',
    goToMyProperty: 'navigation link',
    goToMyReports: 'navigation link',
    goToMyAccount: 'navigation link',
    goToMyDocuments: 'navigation link',
    goToTheFivesVacations: 'navigation link',
    goToExchanges: 'navigation link',

    //benefits
    getBenefitsByContract: 'button get benefits by contract',
    getDetailsTransactionsBenefit: 'button get details transaction benefit',

    closeTransacctionAgreementModal: 'closed to choose in another moment',
    openTransacctionAgreementModal: 'open transacction agreement modal periodicaly when required a sign',
    benefitBalance:'benefit balance',
    Acepted: 'resolution from transacction agreement modal: acepted',
    Rejected: 'resolution from transacction agreement modal: rejected',

    //login
    makeLogin: 'button login',
    searchHousing: 'button housing search',
    selectOwnerHousing: 'button select owner housing',
    // reservations
    resetForm: 'button reset form',
    requestReservation: 'button search rooms for request reservation',
    searchRoomsFailed: 'from button search rooms, request search rooms failed: ',
    requestReservationSuccessfull: 'button request reservation',
    requestReservationCancellationHasBeenSent: 'from the confirm cancellation button in the booking cancellation modal form',
    requestUpdateBooking: 'from button update booking ',
    requestUpdateBookingFailed: 'from button update booking: Failed to update booking ',
    requestUpdateBookingSuccessfull: 'from button update booking: Update success ',
    openRsvCancellationRequest: 'from the dropDown of actions for a booking (cancel booking)',
    showCouponRsv: 'from the dropDown of actions for booking',
    goToEditMyReservation: 'from the dropDown of actions for a booking (edit booking)',

    // documents
    selectCategoryFile: 'select category file',
    downloadFile: 'button download file',

    //profile
    changePassword: 'button change password',
    changePhotoProfile: 'from button change photo profile',
    updatePhotoProfile: 'from button update photo profile',
    uploadPhotoGallery: 'from button upload photo gallery',
    deleteTemporalPhotoGallery: 'from button delete photo gallery no saved',
    deletePhotoGallery: 'from button delete photo gallery',
    savePhotosGallery: 'from button save photos gallery',

    // tabs
    clickTabOption: 'select tab option:',

    //reports
    signReport: 'from button sign report in modal',
    intentToSign: 'from button sign in table',

    //share social promos
    sharePromoFacebook: 'click icon button sharepromofacebook',
    sharePromoTwitter: 'click icon button sharepromotwitter',
    sharePromoLinkedIn: 'click icon button sharepromotlinkedin',
    sharePromoLink: 'click icon button sharepromolink',
    acceptToSharePromotions: "click in button accept to share promotions from modal",
    acceptToSharePromotionsWasSuccessfull: "after click in button accept to share promotions",
    declineToSharePromotions: "click in button decline to share promotions from modal",
    accessControlLogWasCreated: "access control record was created after clicking the save button",
    accessControlLogWasUpdated: "access control record was updated after clicking the updated button",
    accessControlLogWasDeleted: "access control record was deleted after clicking the delete button",

    // Control de acceso
    goToMyPropertyCreateControlRegister: 'button create access control register',
    goToMyPropertyEditControlRegister: 'from button edit access control register in list',

    // Solicitud de mantenimiento
    goToMyPropertyCreateMaintenanceRequest: "from button to go add maintenance request",
    attemptToCreateaMaintenanceRequest: "from the save maintenance request button",
    maintenanceRequestCreationRecord: "from the save maintenance request button",
    goToShowMaintenanceDataRequest: "from the dropDown of actions for a maintenance request (show option)",
    goToEditMaintenanceDataRequest: "from the dropDown of actions for a maintenance request (edit option)",
    goToDeleteMaintenanceDataRequest: "from the dropDown of actions for a maintenance request (delete option)",
    updateMaintenanceDataRequest: "from the update maintenance request button",

    // Exchanges
    goToMyPropertyCreateMaintenanceRequest: "from button to go add maintenance request",
    exchangesViewSubscribe: "from the Exchanges subscribe button",
    exchangesViewTerms: "from the Exchanges terms and use button",
    modalExchanges: "from the Exchanges terms and use modal",
    subscribeToExchanges: "from the Exchanges terms and use modal accept button",

}


export const payloadEvent = ( event, action, component, page = '' ) =>{
    return {
        event: events[event] || '',
        action: actions[action] || '',
        component: components[component] || '',
        page
    }
}
export const userConnected = (data) => {

    const { page } = data

    const userAdminExists = store.state.auth.userAdmin || null
    const owner = store.state.auth.user ? `${ store.state.auth.user.name } ${store.state.auth.user.lastname }` : ''
    const userAdmin = userAdminExists ? `${ store.state.auth.userAdmin.name } ${store.state.auth.userAdmin.lastname }` : ''
    const emailAdmin = userAdminExists ? `${ store.state.auth.userAdmin.email }` : ''
    const email =  store.state.auth.user ? `${ store.state.auth.user.email }` : ''
    // const role = store.state.auth.userAdmin ? `Administrator` : 'Owner'
    const membership = store.state.auth.myContracts?.contractSelected?.membershipcode || ''
    const resort = store.state.auth.myContracts?.contractSelected?.resortsname || ''
    const housing_number = store.state.auth.myContracts?.contractSelected?.housingnumber || ''
    const idContract = store.state.auth.myContracts?.contractSelected?.id || ''
    // const membershipCount = store.state.auth.myContracts?.contractSelected?.membershipcount || ''

    return {
        contract_id: page === 'admin' ? '' : String(idContract),
        resort: page === 'admin' ? '' : resort,
        housing_number: page === 'admin' ? '' : housing_number,
        membership : page === 'admin' ? '' : membership,
        owner: page === 'admin' ? '' : owner,
        email: userAdminExists ? emailAdmin : email,
        // msc: page === 'admin' ? '' : String(membershipCount), //remover
        // role, //pasa a nivel de evento, remover
        // name_user: userAdminExists ? userAdmin : owner, //pasa a nivel de evento
        user_id: userAdminExists ? store.state.auth.userAdmin.idUser : null,
    }
}