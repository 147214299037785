import { showAlertMessage } from "@/helpers/helpers"

//start module state Categories
export function setCategories(state, categories) {     
    let { hotelServices, onlyCategories, withSubCategories, categoryServices } = categories 
    state.categories.hotelServices = hotelServices
    state.categories.onlyCategories = onlyCategories
    state.categories.withSubCategories = withSubCategories
    state.categories.categoryServices = categoryServices
}


export function setSelectedCategory(state, category) {
    state.selectedCategory = category
}

export function setProducts( state, products) {
    state.products = products
}

export function setSortBy( state, option) {
    state.orderBy = option.value
}

export function setCurrencies( state, currencies) {
    state.currencies = currencies
}

export function setHotels( state, hotels) {
    state.hotels = hotels
}

export function setLanguages( state, languages) {
    state.languages = languages
}

export function addProductInCart(state, product) {
    state.cart = [...state.cart, product ]
    calculateTotalBreakdown(state)
    showAlertMessage( 'Producto agregado', 'CheckCircleIcon', `🎉🎉 Se ha agregado el producto: ${product.detaildisplay}.`, 'success', 5000, 'bottom-right')
    setIfRequiredFieldsFilled(state)
}

export function removeProductInCart(state, product) {
    const { uuid, uuidPackage } = product  
    if(uuidPackage){ //si es un item de paquete
        state.cart = state.cart.filter( item => item.uuidPackage !== uuidPackage ) //borro los productos hijos
        state.cart = state.cart.filter( item => item.uuid !== uuidPackage ) //borro el package padre  
    }
    if(!uuidPackage){
        state.cart = state.cart.filter( item => item.uuidPackage !== uuid )   
    }

    state.cart = state.cart.filter( item => item.uuid !== uuid )
    calculateTotalBreakdown(state)
}

export function setCurrencyCustomer(state, currency) {
    state.customerCurrency = currency
}

export function calculateTotalBreakdown(state) {
    let sum = 0
    const decimalDiscount = parseFloat(state.breakdown.discountApplied) / 100
    state.cart.forEach((product) => {
        const precio = (product.discountApplied > 0 ) ? product.salepriceWithDiscount : product.saleprice
        sum += (parseFloat(precio) * parseFloat(product.qty))
    })
    const descuento = sum * decimalDiscount
    state.breakdown.total = ((Math.round(sum * 100) / 100).toFixed(2)) - descuento
}

export function setDetailProduct(state, detail) {
    state.productDetail = detail
}

export function setLoadingProducts( state, bool) {
    state.isLoadingProducts = bool
}

export function logoutMarket(state) {
    state.selectedCategory = null    
    state.customerCurrency = null    
    state.cart = []   
    state.products = []
    state.languages = []
    state.hotels = []
    state.currencies = []
    state.categories.hotelServices = []
    state.categories.onlyCategories = []
    state.categories.withSubCategories = []
    state.categories.categoryServices = []    
}

//para guardar el nombre del cliente del cart
export function setDataCustomer(state, clientData) {
    state.customer.name = clientData.name.trim()
    state.customer.lastname = clientData.lastname.trim()
    state.customer.email = clientData.email.trim()
    state.customer.phone = clientData.phone.trim()
    state.customer.idUser = clientData.idUser
    state.customer.customertype = clientData.customertype
    state.customer.paymentmethod = clientData.paymentmethod
    state.customer.sihotCode = clientData.sihotCode
}

//set id cliente
export function setIdCustomer(state, idCustomer) {            
    state.customer.idCustomer = idCustomer        
}

//
export function setQtyProductDetail( state, payload ) {
    let sum = 0
    const { idProduct, idDetail, qty } = payload
    const product = state.products.find( p => p.id === idProduct ) // busco el producto a mutar su detalle
    const idx = product.detail.findIndex( detail => detail.id === idDetail  ) // busco el detalle por el idDetail
    product.detail[idx].qty = qty //muto su cantidad
    product.detail[idx].subTotal = qty * product.detail[idx].saleprice //genero subTotal
    product.detail.forEach(( detalle ) => {
        sum += parseFloat(detalle.subTotal)
    })
    product.total = String((Math.round(sum * 100) / 100).toFixed(2)) //decimal
}

export function setQtySingleProductDetail( state, payload ) { //usado en producto detalle, single product
    let sum = 0
    const { idDetail, qty } = payload
    let product = state.productDetail
    const idx = product.detail.findIndex( detail => detail.id === idDetail  ) // busco el detalle por el idDetail
    product.detail[idx].qty = qty //muto su cantidad
    product.detail[idx].subTotal = qty * product.detail[idx].saleprice //genero subTotal
    product.detail.forEach(( detalle ) => {
        sum += parseFloat(detalle.subTotal)
    })
    product.total = String((Math.round(sum * 100) / 100).toFixed(2)) //decimal
}

export function setOperationDateProduct(state, payload) {
    const { uuid, date } =  payload
    const idx = state.cart.findIndex( item => item.uuid === uuid ) 
    state.cart[idx].operationdate = date 
    setIfRequiredFieldsFilled(state)
}

export function setBlackoutsDateProduct(state, payload) {
    const { uuid, blackoutDates , maxDate  } =  payload
    const idx = state.cart.findIndex( item => item.uuid === uuid ) 
    state.cart[idx].blackoutDates.disable = blackoutDates    
    state.cart[idx].blackoutDates.maxDate = maxDate    
}

export function resetQtyProductDetail( state, payload ) {
    const { idProduct } = payload    
    const product = state.products.find( p => p.id === idProduct ) // busco el producto a mutar su detalle    
    product.total = '0.00'    
    product.detail.forEach(( detalle ) => {
        detalle.qty = 0         
        detalle.subTotal = 0 
    })
    if(state.productDetail){
        state.productDetail.total = '0.00'    
        state.productDetail.detail.forEach(( detalle ) => {
            detalle.qty = 0         
            detalle.subTotal = 0 
        })
    }
}

export function addProductsFromPackage(state, products) {    
    if(products.length > 0) {
        state.cart = [...state.cart, ...products ]
        state.cart.isPackage = true
        calculateTotalBreakdown(state)        
        showAlertMessage('Productos del paquete', 'CheckCircleIcon', `🎉🎉 ${products.length } productos del paquete han sido agregados.`, 'success', 5000, 'bottom-right')   
        setIfRequiredFieldsFilled(state)
    }   

}

export function changeQtyProductsPackage(state, payload){
    const { uuidPackage , qty} = payload    
    state.cart.items.forEach( product => {
        const { uuidPackage:keyPackage } = product
        if( keyPackage === uuidPackage ){
            product.qty = qty
        }        
    })
}

export function noteCliente(state, payload){
    const { uuid , note } = payload
    const idx = state.cart.findIndex( item => item.uuid === uuid ) 
    state.cart[idx].clientnote = note        
    setIfRequiredFieldsFilled(state)
}

export function setIfRequiredFieldsFilled(state) {
    state.cart.forEach((item) => {
        item.requiredFieldsFilled = false
        if(!item.operationdate){
            item.requiredFieldsFilled = true
        }
    })
}