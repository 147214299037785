<template>
  <div>
    <!-- Agregar aquí tu contenido de la aplicación -->
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { auth } from '@/modules/auth/mixins/auth'

export default {
    mixins: [auth],
    data() {
        return {
            timeoutId: null
        };
    },
    mounted() {
        this.startInactivityTimer()
        // Escuchar eventos de interacción del usuario
        window.addEventListener("mousemove", this.resetInactivityTimer);
        window.addEventListener("keydown", this.resetInactivityTimer);
        window.addEventListener("touchstart", this.resetInactivityTimer);
        window.addEventListener("touchmove", this.resetInactivityTimer);
    },
    computed: {
        ...mapState('appConfig',['windowWidth',]),
        ...mapState('auth',['user','userAdmin']),
    },
    methods: {
        startInactivityTimer() {
            const time=new Date().toString()
            localStorage.setItem('lastActivity', time)
            this.timeoutId = setTimeout(() => {
                // Realizar acciones cuando el usuario esté inactivo, por ejemplo, mostrar un mensaje o cerrar la sesión                
                this._logout()
                
            }, 900000) // 900000 milisegundos (15 minutos) de inactividad
            // 60000 milisegundos es un minuto
        },
        resetInactivityTimer() {
            clearTimeout(this.timeoutId);
            this.startInactivityTimer();
        },
        _logout(){
            this.normalLogout()
        },
    },
    beforeDestroy() {
        // Eliminar los escuchadores de eventos cuando el componente se destruye
        window.removeEventListener("mousemove", this.resetInactivityTimer)
        window.removeEventListener("keydown", this.resetInactivityTimer)
        window.removeEventListener("touchstart", this.resetInactivityTimer)
        window.removeEventListener("touchmove", this.resetInactivityTimer)
    }
};
</script>
