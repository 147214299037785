<template>
    <div class="app-view">
        <div class="wrapper-app">

            <Header :sidebarIsOpen="sidebarIsOpen"  :routes="routes" @open-sidebar="openSidebar"/>

            <Sidebar :sidebarIsOpen="sidebarIsOpen" :routes="routes" @open-sidebar="openSidebar" @close-sidebar="closeSidebar" />

            <div class="sidebar-overlay" id="sidebarOverlay" :class="sidebarIsOpen ? 'sidebar-is-open': ''" ></div>

            <main class="content-app " :class="sidebarIsOpen ? 'sidebar-is-open': ''">

                <router-view />

            </main>

            <AdminRestrictedModal/>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import Header from '@/modules/layouts/components/Header'
    import Sidebar from '@/modules/layouts/components/Sidebar'
    import AdminRestrictedModal from '@/modules/layouts/components/AdminRestrictedModal'

    import { utils } from "@/modules/owners/mixins/utils"

    export default {
        components:{
            Header,
            Sidebar,
            AdminRestrictedModal
        } ,
        data() {
            return {
                sidebarIsOpen: false,
            }
        },
        computed:{
            ...mapState('auth',['user','myContracts','showModules']),
            routes(){
                const categoryRommId = this.myContracts?.contractSelected?.categoryroomId
                const returnTypeContract = this.myContracts?.contractSelected?.returnType

                const userCanBooking = (!!this.user?.canBook || categoryRommId != null)
                const canSeeMyProperty = (this.showModules?.myProperty || false)
                const canSeePromotions = (this.showModules?.promotions || false)
                const canSeeExchanges = ((this.showModules?.exchanges && this.myContracts?.contractSelected?.canSusbcribe) || false)
                // const userCanShowReports = (returnTypeContract != null && returnTypeContract == 2)

                const routes = [
                    { // 0
                        name : 'home', display: this.$t('Dashboard') , icon : 'CakeIcon', show: true, action: 'goToDashboard',
                    },
                    { // 1
                        name : 'my-benefits', display: this.$t('My Benefits'), icon : 'BilleteIcon', show: true,  action: 'goToMyBenefits',
                    },
                    { // 2
                        name : 'my-reservations', display: this.$t('My Reservations'), icon : 'CalendarIcon', show: userCanBooking,  action: 'goToMyReservations'
                    },

                    { // 3
                        name : 'my-reports', display: this.$t('My reports'), icon : 'BarChartIcon', show: true, action: 'goToMyReports'
                    },
                    { // 4
                        name : 'edit-booking', display: this.$t('Edit My Reservation'), icon : 'CalendarIcon', show: false,  action: 'goToEditMyReservation'
                    },
                    { // 5
                        name : 'profile', display: this.$t('My account'), icon : 'UserIcon', show: true, action: 'goToMyAccount',
                    },
                    { // 6
                        name : 'my-property', display: this.$t('My Property'), icon : 'HomeIcon', show: canSeeMyProperty, action: 'goToMyProperty'
                    },
                    { // 7
                        name : 'exchanges', display: this.$t('home exchanges'), icon : 'ShuffleIcon', show: canSeeExchanges, action: 'goToExchange'
                    },
                    { // 8
                        name : 'promotions', display: 'The Fives Vacations', icon : 'SunIcon', show: canSeePromotions, action: 'goToTheFivesVacations'
                    },
                    { // 9
                        name : 'resources', display: this.$t('Resources'), icon : 'FilesIcon', show: true, action: 'goToMyDocuments'
                    },
                    { // 10
                        name : 'create-access-control', display: this.$t('My Property'), icon : 'HomeIcon', show: false, action: 'goToMyPropertyCreateControlRegister'
                    },
                    { // 11
                        name : 'edit-access-control', display: this.$t('My Property'), icon : 'HomeIcon', show: false, action: 'goToMyPropertyEditControlRegister'
                    },
                    { // 12
                        name : 'access-control', display: this.$t('My Property'), icon : 'HomeIcon', show: false, action: 'goToMyPropertyControlRegister'
                    },
                    { // 13
                        name : 'create-maintenance-request', display: this.$t('My Property'), icon : 'HomeIcon', show: false, action: 'goToMyPropertyCreateMaintenanceRequest'
                    },
                    { // 14
                        name : 'edit-maintenance-request', display: this.$t('My Property'), icon : 'HomeIcon', show: false, action: 'goToMyPropertyEditMaintenanceRequest'
                    },
                    { // 15
                        name : 'maintenance-request', display: this.$t('My Property'), icon : 'HomeIcon', show: false, action: 'goToMyPropertyMaintenanceRequest'
                    },
                    { // 16
                        name : 'not-found', display: this.$t('Restricted Access'), icon : 'UserIcon', show: userCanBooking,  action: 'test'
                    },
                ]
                // routes[2].show = userCanBooking //reservas es visible solo si user can book or categoryRommId != null
                // routes[3].show = userCanShowReports // si el tipo de retorno de contrato es no garantizado, returnType = 2, mostrar mis reportes
                // routes[6].show = canSeeMyProperty // si el parámetro está activo, mostrar mi propiedad
                // routes[7].show = canSeePromotions // si el parámetro está activo, mostrar promociones
                // routes[7].show = true // si el parámetro está activo, mostrar promociones

                

                return routes
            }

        },
        methods: {
            openSidebar(){
                this.sidebarIsOpen = true
            },
            closeSidebar(){
                this.sidebarIsOpen = false
            },

        },
    }
</script>

<style lang="scss" scoped>

.app-view{
    height: 100%;
    margin: 0;
    padding: 0;
    // overflow: hidden;
    position: relative;
}
.wrapper-app{
    height: 100vh;
    position: relative;
}
.sidebar-overlay {
    opacity:0.8;
    background: #000;
    height: 100%;

    left: -100%; // pasa a cero cuando se abra el sidebar
    top: 0;
    position: fixed;
    width: 100%;
    z-index: 9;
}

.sidebar-overlay.sidebar-is-open{
  left: 0%; // pasa a cero cuando se abra el sidebar
}

.content-app{
    width: 100%;
    height: 100vh; /* Establecer la altura al 100% del viewport */
    /* height: 100%; */
}

@media (min-width:960px) {

    .sidebar-overlay.sidebar-is-open{
        left: -100%; // No requiero el overlay en pantallas arriba del 960px
    }

    .content-app.sidebar-is-open{
        width: calc(100% - 280px);
        left: 280px;
    }

}
</style>