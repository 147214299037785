import Vue from 'vue'
import {
    ValidationObserver,
    ValidationProvider,
    localize
} from "vee-validate";
import es from "vee-validate/dist/locale/es.json"
import en from "vee-validate/dist/locale/en.json"

import flatpickr from "flatpickr"
import { Spanish } from "flatpickr/dist/l10n/es.js"
import { english } from "flatpickr/dist/l10n/default"

//sobreescribo los datos de vee-validate, una vez obtenido un idioma
const overWriteVeeValidateLocalize = (lang) =>{
    if (lang == 'ENG') localize("en", en)
    else localize("es", es)

    Vue.component("ValidationObserver", ValidationObserver);
    Vue.component("ValidationProvider", ValidationProvider);
}

const overWriteFlatPickrLocalize = (lang) =>{
    if (lang == 'SPA') flatpickr.localize(Spanish)
    else flatpickr.localize(english)

    Vue.use(flatpickr)
}

export {
    overWriteVeeValidateLocalize,
    overWriteFlatPickrLocalize
}