import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import { stringAleatorio, toDecimal, showAlertMessage } from '@/helpers/helpers'
import { currentDate } from '@/helpers/market'
import { version } from '../../../../package'

export const market = {
  data () {
    return {
      //43 es +, 48-57(numeros del 0 al 9, 32 espacio, 45 es - )
      codesAllowed: [32, 43, 45, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57],      
    }
  },
  computed:{
    ...mapState('market', ['categories','customer','customerCurrency','breakdown','cart','currencies','hotels','languages']),
    ...mapState('auth', ['user','myContracts']),
    ...mapGetters('market',['totalDiscountOnAllProducts','totalDiscountOrder']), 
  },
  methods: {      
    ...mapActions('market', ['saveCustomer','fetchBlackoutDatesProduct', 'getInitialContentMarket']),
    ...mapMutations('market',['setDataCustomer','setIdCustomer','setQtyProductDetail','setQtySingleProductDetail','setOperationDateProduct','setBlackoutsDateProduct','setCurrencies','setHotels','setLanguages']),    

    async fetchInitialDataMarket(){
      if (this.currencies?.length == 0 || this.hotels?.length == 0 || this.languages?.length == 0 ) {
        const { currencies, hotels, languages } = await this.getInitialContentMarket()
        this.setCurrencies(currencies)
        this.setHotels(hotels)
        this.setLanguages(languages)
      }
    },
    formatPhoneNumber(event) {
      const keyCode = event.keyCode ? event.keyCode : event.which      
      const respuesta = this.codesAllowed.includes(keyCode)
      if (!respuesta) {
        event.preventDefault()
      }
    },
    async handlerCreateSale(payloadBooking){
      const customer = this.customerOwnerBooking() 
      this.setDataCustomer(customer)   
      const { idCustomer } = this.customer      
      if( !idCustomer){
        const dataCustomer = await this.saveCustomer(this.customer )
        this.setIdCustomer(dataCustomer.id) //tomo el id del cliente de dataCustomer para ejcurtar la mutacion        
      }
      const headersOrder = this.createHeadersOrder() //encabezados
      const detailsOrder = this.detailsOrderBooking(payloadBooking) // detalles de la orden
      const payload = { ...headersOrder, detail: [{...detailsOrder}] } //payload final, datos del cliente y el booking
      console.log(payload)

    },
    customerOwnerBooking(){           
      const { resortsid } = this.myContracts.contractSelected // id del hotel,    
      const { idOwner, name, lastname, phone, email } = this.user
      this.customer.idUser = 1272 // id de DANA, para prueba, se requiere id de un user, qu eno sea el del owner conectado
      this.customer.id = idOwner
      this.customer.customertype = 2  // 2  es propietario
      this.customer.paymentmethod = 1040 // 1040 The Fives Benefit               
      this.customer.sihotCode =  null
      this.customer.sellercanalventa = 25 // 25, es web, o uno que se especifique
      this.customer.hotel = resortsid // id de hotel tomado del contrato seleccionado
      this.customer.name = name 
      this.customer.lastname = lastname 
      this.customer.email = email 
      this.customer.phone = phone 
      return this.customer 
    },
    createHeadersOrder(){
      const { idCustomer, paymentreference, paymentmethod, paymentlink, hotel, customertype, customeragent, sihotCode, language } = this.customer
      const { currencyid } = this.customerCurrency      
      return {
        canalventa: 25,  //25, es web, hardcode,  TODO, obtenerlo de manera dinamica
        cargomanual: false,
        currency: currencyid,
        customer: idCustomer,
        customeragent, customertype,
        discount: this.totalDiscountOrder,
        grossSale: toDecimal( parseFloat( this.totalDiscountOnAllProducts ) + parseFloat( this.breakdown.total ) ),
        hotel, hotelsihotcode: sihotCode,
        language, numreserva: '', orderStatus: 1, paymentlink, paymentmethod, paymentreference,
        puntoventa: 34, //hardcode,  TODO, obtenerlo de manera dinamica
        saleUser: 1272, // id de DANA, TODO, obtenerlo de manera dinamica
        seccioncanal: 35, //hardcode,  TODO, obtenerlo de manera dinamica,
        tax: "0.00",
        totalSale: this.breakdown.total,
      }
    }, 
    detailsOrderBooking(payloadBooking){
      const { idOwner } = this.user
      const { notes, adults, children, dateIn, dateOut, infoBeneficiaries, nights } = payloadBooking
      const ninios = infoBeneficiaries.filter( child => !child.adult ) //obtengo los que no son adultos
      return {
        unitPrice: 1001.37, // falta
        qty: 1,
        discount: "0.00",
        discounttype: "Sin descuento",
        total: "1001.37",
        note: "",
        clientnote: notes,
        product: null,
        package: null,
        event: null,
        booking: null,
        productDetail: null,
        packageDetail: null,
        eventDetail: null,
        serviciosihot: null,
        partial: 0,
        firstpay: 0,
        extrapax_amount: 0,
        extrapax: 0,
        beneficiarios: null,
        isBenefit: false,
        blackout: 0,
        ownerBooking: idOwner,
        orderbooking: {
          adults,
          authorizationUser: null,
          children,
          dateAuthorization: null,
          datein: dateIn,
          dateout: dateOut,
          email: infoBeneficiaries[0].email,
          firtpay: 0,
          isBenefit: false,
          ownerBooking: idOwner,
          isAuthorization: false,
          blackout: 0,
          givenname: infoBeneficiaries[0].name || '',
          hotel: 3,
          inclusions: "",
          includes: [],
          inforates:[], // falta, arreglo de objetos que traen checkin, checkout, rate,
          isRateLimit: false,
          language: "en", // se puede poner "en" por defecto,
          mealplancode: "ASI" ,// falta,
          multimedias: [] ,// sí este es obligatorio, falta!!
          nameprefix: "Mr/Mrs",
          nights,
          ninos: [...ninios.map( child => ({edad: child.edad }) )],
          partial: 0,
          phonenumber: infoBeneficiaries[0].phone || '',
          promotioncode: "",
          rateInit: "1001.37" ,// falta, es lo mismo que unitPrice
          rateManual: 0,
          rateplancode: "AIRACKDM", // falta,
          rateplanid: "2", // falta,
          rateplanname: "ALL INCLUSIVE", // falta,
          roomtypecode: "DLX", // falta,
          roomtypename: "Deluxe Room", // falta,
          surname: infoBeneficiaries[0].lastname || '',
          termscondition: 2, // falta,
          total: "1001.37", // valor calculado
          urlAuthorization: "",
          urlimage: "", // falta, es img de una hab.
          vendorcode: "CONTACTCENTER" //falta,
        }
      }
    },
    //Core market
    generateProductsToAddCart(product){  
      const newDetail = product.detail.filter(detail => detail.qty > 0 )  //busco producto detalle al que se le modificó cantidades
      product.detail = newDetail 
      const items = this.createProductsToAddCart(product)
      if(!items.length){
        const text = `Elija una opción de las variantes, indique una cantidad`
        showAlertMessage( 'Elija una opción', 'InfoIcon', text, 'warning', 3000, 'bottom-right' )
      }
      return items
    },
    createProductsToAddCart(product){
      const totalProducts = product.detail.length
      let items = []      
      for (let index = 0; index < totalProducts; index++) {
        items.push({...product, detailSelected: product.detail[index] }) //genero el arreglo de productos a agregar al cart     
      }
      items.forEach( item => {
        delete item.detail //borro array detail del item        
      })
      return items
    },
    formatProductBeforeAddingToCart(product){
      //DONDE REMUEVO Y AÑADO ELEMENTOS ANTES DE AÑADIR AL CART
      delete product.detail
      delete product.detailSelected.currency
      delete product.detailSelected.seccionventaid
      const { descmax, detaildisplay, id, saleprice, servicesihot, qty } = product.detailSelected
      //AÑADO PROPIEDADES ADICIONALES
      product.uuid = stringAleatorio() //les genero key unicos aleatorios
      product.salepriceWithDiscount = saleprice
      product.originalSalePrice = saleprice
      product.qty = qty || 1
      product.cost = product.cost || '0.00'
      product.itinerario = {}
      product.detaildisplay = detaildisplay   
      product.orderdetailbeo = this.setBeo(product)
      product.isIncludenIn = null
      product.discountApplied = 0
      product.operationdate = null
      product.operationtime = '12:00'
      product.note = ''      
      product.uuidPackage = null
      product.isPromo = false
      product.isBenefit = false      
      product.benefitProduct = ''
      product.contracts = ''
      product.promoName = ''
      product.clientnote = '',
      product.isMain = false
      product.typeDiscount = 'Sin descuento'
      product.orderextraFields = []
      product.requiredFieldsFilled = false
      product.beneficiarios = { fullname: '', phone: '', email: '', room: '', }
      if (product.categoryName == 'Transfer') {
        product.itinerario.nameBenefi = ''
        product.itinerario.lastNameBenefi = ''
        product.itinerario.trip = 'Arrival'
        product.itinerario.extrapax_amount = 0
        product.itinerario.extrapax_num = 0
    }
      product.descmax = parseInt(descmax) || 0
      product.detailId = id
      product.saleprice = saleprice
      product.serviciosihot = servicesihot || null      
      product.blackoutDates = { minDate: 'today', disable:[] , maxDate: ''}                       
      product.belongsToPackage = false

      //REMUEVO detailSelected
      delete product.detailSelected
      //mas deletes
      // delete product.coverImage 
      delete product.category
      delete product.detail
      delete product.currencyCode
      delete product.type
      delete product.gallery
      delete product.videolink
      delete product.weblink
      delete product.productinfo
      delete product.terms
      delete product.servicesihot
      delete product.currency
      delete product.hotel
      delete product.metatag
      delete product.location
      delete product.startDate
      delete product.startTime
      delete product.endDate
      delete product.endDateFormat
      delete product.endTimeFormat
      delete product.hotelName
      delete product.locationName
      delete product.startDateFormat
      delete product.startTimeFormat
      return product
    },
    formatProductsPackage(item){
      const items = []
      const { isProductPackage, packageData, qty, uuid, servicesihot, valueFromCategory } = item
      if(isProductPackage && packageData.length){        
        packageData.forEach( product => {
          product.uuid = stringAleatorio()         
          product.salepriceWithDiscount = product.saleprice
          product.originalSalePrice = product.saleprice
          product.qty = qty         
          product.cost = "0.00"
          product.itinerario = {}
          product.detaildisplay = product.name || ''
          product.orderdetailbeo = this.setBeo(item)
          product.isIncludenIn = null,
          product.discountApplied = 0
          product.operationdate = null
          product.operationtime = '12:00'
          product.note = ''
          product.uuidPackage = uuid
          product.isPromo = false
          product.isBenefit = false
          product.benefitProduct = ''
          product.contracts = ''
          product.promoName = ''
          product.clientnote = '',
          product.isMain = false
          product.typeDiscount = 'Sin descuento'
          product.orderextraFields = []
          product.requiredFieldsFilled = false
          product.beneficiarios = { fullname: '', phone: '', email: '', room: '', }        
          product.blackoutDates = { minDate: 'today', disable:[] , maxDate: ''}
          if ( product.categoryName == "Transfer") {
            product.itinerario.nameBenefi = ""
            product.itinerario.lastNameBenefi = ""
            product.itinerario.trip = 'Arrival'
            product.itinerario.extrapax_amount = 0
            product.itinerario.extrapax_num = 0
          }
          
          product.detailId = product.detail.id
          product.belongsToPackage = true
          product.serviciosihot = servicesihot || null
          product.valueFromCategory = valueFromCategory          
          
          delete product.description
          // delete product.supplierPromo
          delete product.detail
          delete product.weblink   
          delete product.metatag

          items.push(product)
        })
      }      
      return items
    },
    setBeo( product) {
      const { detaildisplay, usabeo } = product
      if (usabeo) {
        return {
          titulo: `BEO  de ${detaildisplay}`,
          backup: null, locacion: null, comentario: '', pax: 0, numreserva: '', detailbeo: []
        }
      }
    return { titulo: '', backup: null, locacion: null, comentario: '', pax: 0, numreserva: '', detailbeo: [] }
    },
    
    async qtyHasChanged(product){
      const{ uuid, qty, operationdate } = product
      const text = `La cantidad ha sido incrementado: La fecha`
      showAlertMessage( 'La cantidad a cambiado', 'InfoIcon', text, 'warning', 3000, 'bottom-right' )
      await this.setConfigBlackoutDatesProduct(product, qty)
      if(operationdate){
        const payload = { uuid, date: null }
        this.setOperationDateProduct(payload)
      }
    },
    async setConfigBlackoutDatesProduct(item, qty){
      const { uuid, id } = item
      const dates = await this.fetchBlackoutDatesProduct({idP:id, qty, fini: currentDate()})
      dates.sort( (a,b) => { return new Date(a.date) - new Date(b.date) })   
      const blackoutDates = dates.map( d => d.date)
      // const maxDate = blackoutDates.length ? blackoutDates[blackoutDates.length - 1] : ''
      const configDate = { uuid, blackoutDates , maxDate: '' }
      this.setBlackoutsDateProduct(configDate)      
    },
    existsInCart( item ) {        
      const { detailId, categoryName, modelType }  = item   
      const prodInCart = this.cart.find( product => product.detailId === detailId && product.modelType === modelType )
      return ( prodInCart && categoryName != "Transfer" ) ? true : false      
    },
    handlerQtyProduct( product, detail){
      const idProduct = product.id
      const idDetail = detail.id
      const payload = { idProduct, idDetail, qty: detail.qty }
      this.setQtyProductDetail( payload )    
    },
    handlerQtyProductSingle(product, detail){
      const idProduct = product.id
      const idDetail = detail.id
      const payload = { idProduct, idDetail, qty: detail.qty }
      this.setQtySingleProductDetail( payload )    
    },
    categoriesIsEmpty(){   
      let isEmpty = true   
      const { hotelServices, onlyCategories, withSubCategories, categoryServices } = this.categories
      if(hotelServices.length){
        isEmpty = false
      }
      if(onlyCategories.length){
        isEmpty = false
      }
      if(withSubCategories.length){
        isEmpty = false
      }
      if(categoryServices.length){
        isEmpty = false
      }
      return isEmpty
    },
    currenciesHotelsLanguagesIsEmpty(){   
      let isEmpty = true         
      if(this.currencies.length){
        isEmpty = false
      }
      if(this.hotels.length){
        isEmpty = false
      }
      if(this.languages.length){
        isEmpty = false
      }      
      return isEmpty
    },
    clearStorage(){      
      const localVersion = localStorage.getItem('appVersion')
      if(!localVersion){        
        localStorage.removeItem('vuex') //clear vuex
        localStorage.setItem('appVersion', version)
        window.location.reload(true)
      }
      if( localVersion ){
        if( localVersion !== version ){          
          localStorage.removeItem('vuex') //clear vuex
          localStorage.setItem('appVersion', version)
          window.location.reload(true)
        }
      }
    },
  }
}

