import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '@/store'

Vue.use(VueI18n)
function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}
let language = 'English' // por defecto pongo esto por si objeto user no viene
const userLanguage = store.state.auth.user?.languageName // el usuario conectado trae un idioma
if(userLanguage){
  language = userLanguage
}
const localeDefault = language === 'English' ? 'en' : 'es'
export default new VueI18n({
  locale: localeDefault,
  fallbackLocale: 'en',
  messages: loadLocaleMessages(),
})
