import store from '../../../store'

const checkIfUserIsAutenticated = (to, from, next) => {

    const { isLogged } =  store.state.auth    
    if ( !isLogged  ) next()
    else next( {name: 'home'} )    
}

export default checkIfUserIsAutenticated
