export function setBenefitsToContractSelected(state, benefits) {
    state.benefitsByContract = benefits
}

export function setBenefitSelected(state, payload) {
    const { idBenefit, bool } =  payload
    const idx = state.benefitsByContract.findIndex( benefit => benefit.id === idBenefit ) 
    state.benefitsByContract[idx].isSelected = bool    
}

export function setBenefitDetailTransaccion(state, detail) {
    state.benefitDetailsTransaccion = detail
}

export function setMyProfits(state, myProfits) {
    state.profits.myProfits = myProfits
}

export function setAnualProfit(state, anualProfits) {
    state.profits.anual = anualProfits
}

export function setHotelPerformance(state, hotelPerformance) {
    state.hotelPerformance = hotelPerformance
}

export function setLastTransactions(state, lastTransactions) {
    state.lastTransactions = lastTransactions
}

export function setNewsletter(state, newsletter) {
    state.newsletter = newsletter
}

export function setLanguages(state, langs) {
    state.languages = langs
}


export function setNextReservation(state, nextReservation) {
    state.nextReservation = nextReservation
}

export function setOwnerInHouse(state, ownerInHouse) {
    state.ownerInHouse = ownerInHouse
}

export function setBookings(state, bookings) {
    state.bookings = bookings
}

export function setResources(state, payload) {
    const { resourcesOne, resourcesTwo } = payload
    state.myFiles.listOne = resourcesOne
    state.myFiles.listTwo = resourcesTwo
}

export function setMyFiles(state, payload) {
    const { filesOne, filesTwo } = payload
    state.myFiles.myFilesOne = filesOne
    state.myFiles.myFilesTwo = filesTwo
}
export function setContractWasChangedOwners(state) {
    state.benefitsByContract = []  
    state.benefitDetailsTransaccion = []   
    state.profits.myProfits = []
    state.profits.anual = null
    state.lastTransactions = []
    state.myFiles.listOne = []
    state.myFiles.listTwo = []
    state.myFiles.myFilesOne = []
    state.myFiles.myFilesTwo = []
}

export function setDataOwnerLogout(state) {
    state.benefitsByContract = []  
    state.benefitDetailsTransaccion = []
    state.profits.myProfits = []
    state.profits.anual = null
    state.hotelPerformance = null
    state.lastTransactions = []
    state.newsletter = []
    state.myFiles.listOne = []
    state.myFiles.listTwo = []
    state.myFiles.myFilesOne = []
    state.myFiles.myFilesTwo = []
}

export function setDataHousing(state, data) {    
    state.housing = data
}

export function setSelectedTabProfits(state, index) {
    state.selectedTabProfits = index
}

export function setTypeDocument(state, typeDocument) {
    state.typeDocument = typeDocument
}

export function setCancellationReasons(state, cancellationReasons) {
    state.cancellationReasons = cancellationReasons
}

export function setCondominiumFees(state, condominiumFees) {
    state.condominiumFees = condominiumFees
}

export function setExchangesSubscriptionInfo(state, exchangesSubscriptionInfo) {
    state.exchangesSubscriptionInfo = exchangesSubscriptionInfo
}