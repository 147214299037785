import { consoleMsgFinally } from '@/helpers/helpers'
import { makeArrayCategories, formatListProducts, formatListPackage, formatListEvents, currentDate } from '@/helpers/market'
import axiosClient from '@/libs/axios'


export const getInitialContentMarket = async () => {   
    const payloadHotels = { onlyDingusCode: false, all: false, showInWeb: true }                               
    const content = await Promise.all([        
        fetchCurriencies(), fetchHotels(payloadHotels), fetchLanguages()
    ])        
    const [ currencies, hotels, languages ] = content           
    return { currencies, hotels, languages }
}

export const getInitialContentCheckout = async () => {   
    const payloadHotels = { onlyDingusCode: false, all: false, showInWeb: true }                      
         
    const content = await Promise.all([        
        fetchHotels(payloadHotels), fetchLanguages()
    ])        
    const [ hotels, languages ] = content           
    return { hotels, languages  }
}

export const fetchCategories = async () => {   
    try {                
        const { data } = await axiosClient(`/catalogs/category/`)                     
        return  makeArrayCategories(data)
    } catch (error) {
        // return { ok: false, message: error.response.data.error.message}
        return { ok: false, data: null,  message: 'error en la petición' }
    } finally {
        consoleMsgFinally('fetchCategories end')
    }
}

export const fetchHotels = async (payload) => {   
    
    const { onlyDingusCode, all , showInWeb } = payload

    try {                
        const { data } = await axiosClient(`/catalogs/hotels/`)   
        if( onlyDingusCode ){
            const hotels = data.filter( hotel => hotel.dingusCode != null )
            return hotels
        }         
        if( all ){            
           return data
        } 

        if( showInWeb ){
            const hotels = data.filter( hotel => hotel.showinweb )
            return hotels
        }                   
    } catch (error) {
        // return { ok: false, message: error.response.data.error.message}
        return { ok: false, data: null,  message: 'error en la petición' }
    } finally {
        consoleMsgFinally('fetchHotels end')
    }
}

export const getHotels = async(context, payload ) => {     
    const { onlyDingusCode, all , showInWeb } = payload

    try {                
        const { data } = await axiosClient(`/catalogs/hotels/`)   
        if( onlyDingusCode ){
            const hotels = data.filter( hotel => hotel.dingusCode != null )
            return hotels
        }         
        if( all ){            
           return data
        } 

        if( showInWeb ){
            const hotels = data.filter( hotel => hotel.showinweb )
            return hotels
        }                   
    } catch (error) {
        // return { ok: false, message: error.response.data.error.message}
        return { ok: false, data: null,  message: 'error en la petición' }
    } finally {
        consoleMsgFinally('fetchHotels end')
    }
}
export const fetchLanguages = async () => {       
    try {                
        const { data } = await axiosClient(`/catalogs/language/`)   
        return data                   
    } catch (error) {        
        return { ok: false, data: null,  message: 'error en la petición' }
    } finally {
        consoleMsgFinally('fetchLanguages end')
    }
}
export const fetchCurriencies = async () => {   
    try {                
        const { data } = await axiosClient(`/catalogs/currency/`)                     
        return data
    } catch (error) {
        return { ok: false, data: null,  message: 'error en la petición' }
    } finally {
        consoleMsgFinally('fetchCurriencies end')
    }
}

export const fetchBestSellerProducts = async () => {   
    try {                
        const { data } = await axiosClient(`/products/`, { params: { bs: 1, idseccanal:34 } } )              
        return  formatListProducts( data, 'BestSeller-01' )
    } catch (error) {
        // return { ok: false, message: error.response.data.error.message}
        return { ok: false, data: null,  message: 'error en la petición' }
    } finally {
        consoleMsgFinally('fetchBestSellerProducts end')
    }
}

export const fetchProductsByCategories = async(context, category ) => {     
    try {                
        const { data } = await axiosClient(`/products/`, { params: { idcat: category.value, idseccanal:34 } } )              
        return formatListProducts( data, category.value) 
    } catch (error) {
        // return { ok: false, message: error.response.data.error.message}
        return { ok: false, data: null,  message: 'error en la petición' }
    } finally {
        consoleMsgFinally('fetchProductsByCategories end')
    }
}

export const fetchProductsBySubCategory = async(context, subCategory ) => { 
    const { idSubCategory, value } = subCategory
    try {                
        const { data } = await axiosClient(`/products/`, { params: { idsub: idSubCategory, idseccanal:34 } } )              
        return formatListProducts( data, value) 
    } catch (error) {
        // return { ok: false, message: error.response.data.error.message}
        return { ok: false, data: null,  message: 'error en la petición' }
    } finally {
        consoleMsgFinally('fetchProductsByCategories end')
    }
}


export const fetchPackages = async(context ) => {      
    try {                
        const { data } = await axiosClient(`/packages/`, { params: { idseccanal:34 } } )              
        return formatListPackage( data, 'f20a5f0e-7d3e-11ec-90d6-0242ac120003')  
    } catch (error) {
        // return { ok: false, message: error.response.data.error.message}
        return { ok: false, data: null,  message: 'error en la petición' }
    } finally {
        consoleMsgFinally('fetchPackages end')
    }
}

export const fetchEvents = async(context ) => {      
    try {                
        const { data } = await axiosClient(`/events/`, { params: { idseccanal:38 } } )         
        const todayDate = currentDate()
        const fecha = new Date(todayDate)
        //se filtra los eventos que sean iguales o mayores a la fecha actual
        const result = data.filter(item => new Date(item.endDate) >= fecha)             
        return formatListEvents( result, 'e634becc-7d3e-11ec-90d6-0242ac120003' )     
    } catch (error) {
        // return { ok: false, message: error.response.data.error.message}
        return { ok: false, data: null,  message: 'error en la petición' }
    } finally {
        consoleMsgFinally('fetchEvents end')
    }
}

export const fetchProductDetail = async(context, payload ) => {  
    const { idProducto, endPoint } = payload  

    try {                
        const { data } = await axiosClient(`/${endPoint}/`, { params: { id: idProducto, idseccanal:34 } } )               
        let product = null
        if (endPoint == 'packages') {
            product = formatListPackage( data, 'f20a5f0e-7d3e-11ec-90d6-0242ac120003')
        }

        if (endPoint == 'products') {                       
            const categoryName = data[0].category.name || ''                        
            product = formatListProducts( data, categoryName )        
        }

        if (endPoint == 'events') {
            product = formatListEvents( data, 'e634becc-7d3e-11ec-90d6-0242ac120003')        
        }        
        return product[0] //regreso el priemr objeto porque este es un array
  
    } catch (error) {
        // return { ok: false, message: error.response.data.error.message}
        return { ok: false, data: null,  message: 'error en la petición' }
    } finally {
        consoleMsgFinally('fetchProductDetail end')
    }
}


export const saveCustomer = async(context, cliente) => {      
    try {                
        const { data } = await axiosClient.post(`/customers/`, cliente )                                                 
        return data
    } catch (error) {        
        return { ok: false, data: null,  message: 'error en la petición' }
    } finally {
        consoleMsgFinally('saveCustomer end')
    }
}

//Obtener fechas no disponibles por  producto
export const fetchBlackoutDatesProduct = async(context, datos) => {
    const { idP, qty, fini  } = datos
    try {                        
        const { data } = await axiosClient(`/allotment/notavailable/`, { params: { idP, qty, fini } } )               
        return data
    } catch (error) {        
        return { ok: false, data: null,  message: 'error en la petición' }
    } finally {
        consoleMsgFinally('fetchBlackoutDatesProduct end')
    }
}
