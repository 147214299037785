import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

// Modules
import appConfig from '@/modules/appConfig/store'
import auth from '@/modules/auth/store'
import owners from '@/modules/owners/store'
import market from '@/modules/market/store'
import myProperty from '@/modules/myProperty/store'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['auth', 'market']
})

export default new Vuex.Store({
  modules: {
    appConfig,
    auth,
    owners,
    market,
    myProperty
  },
  plugins:[vuexLocal.plugin],

  strict: process.env.DEV,
})