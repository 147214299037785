import { mapState, mapActions, mapMutations } from 'vuex'
import { payloadEvent, userConnected } from '@/helpers/pushActions'

export const utils = {
	data () {
		return {      
		}
  	},
	computed: {
		...mapState('owners', ['profits','nextReservation','newsletter']),
		...mapState('auth', ['user', 'userAdmin','myContracts']),  
		isAdmin(){
			return this.userAdmin ? true : false
		}
	},
	methods: {  
		...mapActions('owners', ['getContentTopCards', 'getGenericParameters','pushActionsService']),
		...mapMutations('owners', ['setNewsletter','setNextReservation', 'setOwnerInHouse']),				
		kFormatter(num){
			return (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)
		},
		showAlertIfAdminCannotDoThis(){
			// validar si es admin desde mixin en utils
            if( this.isAdmin ){
                return this.$swal.fire({icon: "error", title: `${this.$t("Restricted Access")}`, html: `${this.$t("Text Restricted Access Admin")}`});
            }
		},
		percentFormatter(num){
			return `ROI ${num}% `
		},
		resolveInvoiceStatusVariantAndIcon (status){
			if (status === 'Partial Payment') return { variant: 'warning', icon: 'PieChartIcon' }
			if (status === 'Paid') return { variant: 'success', icon: 'CheckCircleIcon' }
			if (status === 'Downloaded') return { variant: 'info', icon: 'ArrowDownCircleIcon' }
			if (status === 'Draft') return { variant: 'primary', icon: 'SaveIcon' }
			if (status === 'Sent') return { variant: 'secondary', icon: 'SendIcon' }
			if (status === 'Past Due') return { variant: 'danger', icon: 'InfoIcon' }
			return { variant: 'secondary', icon: 'XIcon' }
		},
		avatarText (value) {
			if (!value) return ''
			const nameArray = value.split(' ')
			return nameArray.map(word => word.charAt(0).toUpperCase()).join('')
		},
		resolveClientAvatarVariant(status ){
			if (status === 'Partial Payment') return 'primary'
			if (status === 'Paid') return 'danger'
			if (status === 'Downloaded') return 'secondary'
			if (status === 'Draft') return 'warning'
			if (status === 'Sent') return 'info'
			if (status === 'Past Due') return 'success'
			return 'primary'
		},
		rowClass(item, type) {
			const colorClass = 'table-info'
			if (!item || type !== 'row') { return }

			// eslint-disable-next-line consistent-return
			if (item.isSelected === true ) { return colorClass }
		},
		async getDataTopCards(){      
			const { id, housingid } = this.myContracts.contractSelected // id del contrado seleccionado
			const payload = { idOwner: this.user.idOwner, idContract: id, housingid }
			const { newsletters, nextReservation } = await this.getContentTopCards(payload)        
			this.setNewsletter(newsletters)
			this.setNextReservation(nextReservation?.datos)
			this.setOwnerInHouse(nextReservation?.ownerInHouse)
		},
		async getParameters(id){
			const requiredParameter = await this.getGenericParameters(id)
			return requiredParameter
		},
		async pushData(event, action, component, routeName, aditionalInfo) {
			const dataUser = userConnected( { page: this.$route.name } )
			const dataPush = {
				user: dataUser.user_id,
				origin: process.env.VUE_APP_EVENT_LOG_ORIGIN || 1,
				event:{ ...payloadEvent(event, action, component, routeName) },
				data: { ...dataUser }
			}

			if(aditionalInfo){
				dataPush.event.component += ` ${aditionalInfo}`  //concateno
			}
			delete dataPush.data.user_id
			await this.pushActionsService(dataPush)
		}
	}
}

