<template>
  <div>
    <component :is="layout" />
    <scroll-to-top v-if="true" />
    <InactivityTimer v-if="!!user"/>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import ScrollToTop from '@/modules/layouts/components/ScrollToTop'

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { useWindowSize, useCssVar } from '@vueuse/core'
import { market } from '@/modules/market/mixins/market'
import { auth } from '@/modules/auth/mixins/auth'
import AdminLayout from '@/modules/layouts/AdminLayout'
import LoginAdminLayout from '@/modules/layouts/LoginAdminLayout'
import LoginLayout from '@/modules/layouts/LoginLayout'
import InactivityTimer from '@/modules/shared/InactivityTimer'

import { overWriteVeeValidateLocalize, overWriteFlatPickrLocalize } from "@/helpers/authHelper"
// import { getDateDiff } from "@/helpers/helpers"


import store from '@/store'

export default {
  mixins: [market, auth],
  components: { 
    ScrollToTop,
    AdminLayout,
    LoginLayout,
    LoginAdminLayout,
	InactivityTimer
    //QuickAccess
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  created(){
    this.manageVerifySesion()
    store.commit('appConfig/UPDATE_WINDOW_WIDTH', window.innerWidth)           
    this.setValidatorsLanguage()
  },
  mounted(){
    window.addEventListener("resize", () => {
      store.commit('appConfig/UPDATE_WINDOW_WIDTH', this.windowS() )                 
    })
    window.addEventListener("focus", () => {
      this.manageVerifySesion()
    })
  },

  data() {
    return {
      layout: null,
    }
  },
  computed: {
    ...mapState('auth',['user','userAdmin', 'isLogged']),

    userLanguage(){
      return this.user && this.user.languageName ? this.user.languageName : 'English'      
    }
  },
  //seteo del lenguage para translation
  watch: {
    // userLanguage () {                
    //   const newLocale = this.userLanguage === "English" ? "en" : "es"
    //   this.$i18n.locale = newLocale  
    //   if( this.user ){
    //     this.setLanguageUser(this.userLanguage)
    //   }    
    // },
    // el watcher vigila el cambio del router y le carga el layout de manera dinamica
    $route(to) {
      if(to.meta.layout){
        this.layout = to.meta.layout
      } else {
        this.layout = "LoginLayout"
      }      
    },    
    user(){
      this.setValidatorsLanguage()
    }
  },  
  methods:{
    ...mapMutations('auth', ['setLanguageUser'] ),
    windowS(){
      const valor = useWindowSize()
      return valor.width.value
    },
    setValidatorsLanguage(){
      if (this.isLogged && this.user?.languageCode) {
        overWriteVeeValidateLocalize(this.user?.languageCode)
        // overWriteFlatPickrLocalize(this.user?.languageCode)
      }
    },
    manageVerifySesion(){
      try {
        const requiresLogout = this.checkStorageTimeOut()
        const requiresUpdate = this.checkVersion()
        if (requiresLogout || requiresUpdate) {
          this.normalLogout()
        }
      } catch (error) {
        console.warn(error)
      }
    },
    checkStorageTimeOut(){
      const lastActivity = localStorage.getItem('lastActivity')
      let reload = false
      if (!!lastActivity) {
        // console.log('Última actividad: ', new Date(lastActivity), `Hace ${getDateDiff({d1: new Date, d2: new Date(lastActivity)}, 'Minutes')} minutos` )
        if ((new Date - new Date(lastActivity)) > 900000) {
          // console.log('Se cerrará la sesión por inactividad');
          localStorage.removeItem('lastActivity') //clear lastActivity
          reload = true
        }
      }
      return reload
    }
  }
}
</script>
<style>
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  position: relative;
  font-weight: normal;
}
</style>