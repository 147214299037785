import { stringAleatorio } from "./helpers"

const makeArrayCategories = ( categories ) => {

    let hotelServices = []
    let onlyCategories = []
    let withSubCategories = []      
    let categoryServices = [
        { section: 'Services', action: 'get-events', text: 'Events', value: 'e634becc-7d3e-11ec-90d6-0242ac120003' },
        { section: 'Services', action: 'get-packages', text: 'Packages', value: 'f20a5f0e-7d3e-11ec-90d6-0242ac120003' },
    ]

    categories.forEach((category) => {
        const { section, id, name } = category
        category.action = 'get-products-by-category'
        category.text = name
        category.value = id
        delete category.id
        delete category.name
        if (section == 'Servicios Hoteleros') {
            hotelServices.push(category)
        }
        if(!category.subcategory.length && section == 'Categorías'){
            category.action = 'get-products-by-category'            
            onlyCategories.push( category )
            onlyCategories.sort((a, b) => a.text > b.text ? 1 : -1) //ordeno alfabeticamente                                   
        }        
        if(category.subcategory.length){    
            category.action = 'get-products-by-sub-category'
            category.uuid = stringAleatorio()
            withSubCategories.push( category )
            withSubCategories.sort((a, b) => a.text > b.text ? 1 : -1) //ordeno alfabeticamente                        
            category.subcategory.forEach( subCat => {
                subCat.uuid = stringAleatorio()
                subCat.idCategory = category.value 
                subCat.nameCategory = category.text                 
            })
        }          
    })
    const bestSeller = { section: 'Categorías', action: 'get-products-best-seller', text: 'Best Seller', value: 'BestSeller-01' }
    onlyCategories.unshift(bestSeller)
    return { hotelServices, onlyCategories, withSubCategories, categoryServices }
}

const formatListProducts = (products, valorCategory) => {
    let items = []
    products.forEach((product ) => {
        product.modelType = 'product'
        product.valueFromCategory = valorCategory
        product.categoryName = product.category.name || ''
        product.total = '0.00'    
        delete product.gallery
        delete product.videolink
        delete product.weblink
        delete product.type
        delete product.category
        delete product.productinfo
        delete product.detailId
        delete product.productavailability
        delete product.rulesProduct 
        delete product.supplier 
        // delete product.supplierPromo         
        // delete product.supplier
        // delete product.supplierPromo
        // delete product.usabeo
        if( product.detail.length ){
            product.detail.forEach( detail => {
                detail.qty = 0
                detail.subTotal = 0                
                delete detail.seccionventaid 
            })
            items.push(product) 
        }     
    })

    return items
}

const formatListPackage = (packages, valorCategory) => {
    packages.forEach((product) => {
        product.modelType = 'package'
        product.valueFromCategory = valorCategory
        product.total = '0.00'    
        product.categoryName = 'Packages' 
        product.detail = [] 
        product.isProductPackage = true
        if(product.gallery.length){
            product.gallery.forEach((item) => {
                item.uuid = stringAleatorio()
            })
        }
        if(product.products.length){
            product.products.forEach((item) => {
                item.uuid = stringAleatorio()
            })
        }

        if(product.packageData.length){
            product.packageData.forEach((item) => {
                item.uuid = stringAleatorio()                
            })
        }

        product.detail.push({
            descmax: 0,
            detaildisplay: product.name,
            extrapax: false,
            id: product.id,
            saleprice: product.saleprice,
            servicesihot: product.servicesihot,
            qty: 0,
            subTotal: 0
        })
        delete product.products
        delete product.gallery
        delete product.videolink
        delete product.weblink
        delete product.category
        delete product.productinfo
    })

    return packages
}

const formatListEvents = (events, valorCategory) => {
    events.forEach((product) => {
        const timeInitFormat = product.startTime.slice(0, -3)
        const timeEndFormat = product.endTime.slice(0, -3)
        const ampmInit = (timeInitFormat.slice(0, -3) >= 12) ? "PM" : "AM"
        const ampmEnd = (timeEndFormat.slice(0, -3) >= 12) ? "PM" : "AM"
        product.modelType = 'event'
        product.categoryName = 'Events'
        product.valueFromCategory = valorCategory
        product.isProductPackage = false        
        const detalleIngles = product.eventdetail[1]
        product.detail = [] 
        product.detail.push( {
            descmax: detalleIngles ? parseInt(detalleIngles.descmax) : 0,
            detaildisplay: product.name,
            extrapax: false,
            id: detalleIngles ? detalleIngles.id : product.id,
            saleprice: product.saleprice,
            servicesihot: detalleIngles ? detalleIngles.servicesihot : null,
            isProductPackage: false
        })
        product.startDateFormat = formatDate(product.startDate, 'en')
        product.endDateFormat = formatDate(product.endDate, 'en')
        product.startTimeFormat = String(timeInitFormat) + ' ' + ampmInit
        product.endTimeFormat = String(timeEndFormat) + ' ' + ampmEnd
        product.currencyCode = product.currency.code
        product.hotelName = product.hotel.name
        product.locationName = product.location.name
        product.description = detalleIngles ? detalleIngles.description : ''
        delete product.eventdetail
        delete product.gallery
        delete product.currency
        delete product.hotel
        delete product.location
        delete product.category
        delete product.productinfo
    })

    return events
}
const formatDate = (fecha, locale) => {

    const date = new Date(fecha + 'T00:00:00')

    const mesesSpanish = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]

    const mesesIngles = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

    const dia = date.getDate()
    const mes = date.getMonth()
    const yyy = date.getFullYear()

    const stringEs = dia + '  ' + mesesSpanish[mes] + ' ' + yyy
    const stringEn = mesesIngles[mes] + ' ' + dia + ', ' + yyy

    const stringDate = locale == "es" ? stringEs : stringEn

    return stringDate
}

const currentDate = () => {
    let date = new Date();
    // adjust 0 before single digit date
    let day = ("0" + date.getDate()).slice(-2);
    // current month
    let month = ("0" + (date.getMonth() + 1)).slice(-2)
        // current year
    let year = date.getFullYear()

    return year + "-" + month + "-" + day
}

const formatItemProduct = (item, type) => {    
    if ( type == 'packages') {
        return formatPackage(item)            
    }
    if ( type == 'products') {
        return formatProduct(item)            
    }

    if ( type == 'events') {
        return formatEvent(item)
    }
}

const formatPackage = (item) => {
    item.categoryName = 'Packages'
    item.modelType = 'package'    
    item.detaildisplay = item.name
    if (item.gallery) {
        item.gallery.forEach((item) => {
            item.key = stringAleatorio()
        })
    }
    if (item.products) {
        item.products.forEach((item) => {
            item.keyItem = stringAleatorio()
        })
    }
    item.detailSelected = {
        cost: item.cost || 0,
        descmax: 0,
        detailDesc: item.name,
        detaildisplay: item.name,
        
        id: item.id,
        saleprice: item.saleprice, 

    }
    return item
}

const formatProduct = (item) => {
    item.categoryName = 'Products'
    item.modelType = 'product'
    
    if (item.gallery) {
        item.gallery.forEach((item) => {
            item.key = stringAleatorio()
        })
    }
    if (item.products) {
        item.products.forEach((item) => {
            item.keyItem = stringAleatorio()
        })
    }
    item.detailSelected = item.detail[0] ? item.detail[0] : null

    delete item.productavailability
    delete item.rulesProduct
    delete item.supplier
    delete item.supplierPromo
    delete item.videolink
    delete item.weblink    

    return item
}

const formatEvent = event => {
    delete event.currency
    event.categoryName = 'Events'
    event.modelType = 'event'
    event.hotelName = event.hotel.name
    event.locationName = event.hotel.name
    event.description = event.name
    event.detailDesc = event.name
    event.detaildisplay = event.name
    const timeInitFormat = event.startTime.slice(0, -3)
    const timeEndFormat = event.endTime.slice(0, -3)
    const ampmInit = (timeInitFormat.slice(0, -3) >= 12) ? "PM" : "AM"
    const ampmEnd = (timeEndFormat.slice(0, -3) >= 12) ? "PM" : "AM"
    event.startDateFormat = formatDate(event.startDate, 'en')
    event.endDateFormat = formatDate(event.endDate, 'en')
    event.startTimeFormat = String(timeInitFormat) + ' ' + ampmInit
    event.endTimeFormat = String(timeEndFormat) + ' ' + ampmEnd
    event.detailSelected = {
        cost: event.cost || 0,
        descmax: 0,
        detailDesc: event.name,
        detaildisplay: event.name,
        id: event.id,
        saleprice: event.saleprice,   
        
    }    
    delete event.endDate
    delete event.endTime
    delete event.startDate
    delete event.startTime
    delete event.location

    return event
}


export { 
    makeArrayCategories,
    formatListProducts,
    formatListPackage,
    formatListEvents,
    formatDate,
    currentDate,
    formatItemProduct
}