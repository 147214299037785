import { isAuthenticatedGuard } from './auth-guard'
import checkIfUserIsAutenticated from './check-user-guard'

export default [
    
    {
        path: '/login',
        name: 'auth-login',
        component: () => import(/*webpackChunkName: "LoginView"*/'@/modules/auth/views/Login'),             
        beforeEnter: checkIfUserIsAutenticated,
        meta: {
            layout: 'LoginLayout',
        },
    },
    {
        path: '/my-account',
        name: 'profile',        
        component: () => import(/*webpackChunkName: "AccountSetting"*/'@/modules/auth/views/AccountSetting'),                  
        beforeEnter: isAuthenticatedGuard,
        meta: {
            layout: 'AdminLayout',
        },
    },    
    {
        path: '/forgot-password',
        name: 'auth-forgot-password',
        component: () => import(/*webpackChunkName: "ForgotPassword"*/ '@/modules/auth/views/ForgotPassword'),  
        beforeEnter: checkIfUserIsAutenticated,        
        meta: {
            layout: 'LoginLayout',                   
        }            
    },
    {
        path: '/reset-password',
        name: 'auth-reset-password',
        component: () => import(/*webpackChunkName: "ResetPassword"*/  '@/modules/auth/views/ResetPassword'),  
        beforeEnter: checkIfUserIsAutenticated,        
        meta: {
            layout: 'LoginLayout',                   
        }            
    }  
]