import { consoleMsgFinally, formatContracts } from '@/helpers/helpers'
import axiosClient from '@/libs/axios'

export const signIn = async (context, user) => {
    try {
        const { data } = await axiosClient.post(`/ownerlogin/`, user)
        delete data.fp
        delete data.infocanal
        delete data.permisosFront
        delete data.puntosventa
        delete data.puntosventa
        delete data.permisosModul

        return { ok: true, user: data, message: 'User logged'}
    } catch (error) {
        return { ok: false, data: null, message: error?.response?.data?.message || 'Los datos ingresados para el login son incorrectos'}
        // return { ok: false, data: null, message: 'Los datos ingresados para el login son incorrectos' }
    } finally {
        consoleMsgFinally('signIn end')
    }
}
export const readInfoOwner = async (context, idlOwner) => {
    try {
        const { data } = await axiosClient(`/ownerlogin/`, { params: { idlOwner } })
        return { ok: true, user: data, message: 'User logged'}
    } catch (error) {
        // return { ok: false, message: error.response.data.error.message}
        return { ok: false, data: null,  message: 'Los datos ingresados para el login son incorrectos' }
    } finally {
        consoleMsgFinally('readInfoOwner end')
    }
}

export const changePassword = async (context, payload) => {
    try {
        const { data } = await axiosClient.put(`/fivesClub/changepassword/`, payload)
        return data
    } catch (error) {
        return error.response.data
    } finally {
        consoleMsgFinally('changePassword end')
    }
}


export const getContractsUser = async (context, payload) => {
    const { idOwner } = payload
    try {
        const { data } = await axiosClient(`/fivesClub/getContractOwner/`, { params: { idOwner } })
        const { status, data:datos } = data
        return status ? formatContracts(datos) : []
    } catch (error) {
        return error.response.data
    } finally {
        consoleMsgFinally('getContractsUser end')
    }
}

export const getPhotosGalleryUser = async (context, payload) => {
    const { idOwner } = payload
    try {
        const { data } = await axiosClient(`/fivesClub/galleryOwners/`, { params: { idOwner } })
        const { status, data:datos } = data
        return status && datos.urlmomentpicture.length ? datos.urlmomentpicture : []
    } catch (error) {
        return error.response.data
    } finally {
        consoleMsgFinally('getPhotosGalleryUser end')
    }
}


export const updatePhotoOwner = async (context, payload) => {
    const { photo } = payload

    try {
        const formData = new FormData()
        formData.append('file', photo)
        formData.append('data', JSON.stringify(payload))
        const { data } = await axiosClient.put(`/fivesClub/updateStatusOwners/`, formData)
        return data

    } catch (error) {
        return error.response.data
    } finally {
        consoleMsgFinally('updatePhotoOwner end')
    }
}

export async function savePhotosGallery(context, payloadPhotos) {
    const responses = []
    const requests = payloadPhotos.map( payload => savePhotoOwner( payload ) ) //generate array of promises to make requests
    const result = await Promise.all( requests ) // return an object as a response
    responses.push( result )
    return responses
}


export const savePhotoOwner = async ( payload ) => {
    const { photo } = payload
    try {
        const formData = new FormData()
        formData.append('file', photo )
        formData.append('data', JSON.stringify(payload))
        const headers = { 'Content-Type': 'multipart/form-data' }
        const { data } = await axiosClient.post(`/fivesClub/galleryOwners/`, formData, headers)
        return data

    } catch (error) {
        return error.response.data
    } finally {
        consoleMsgFinally('savePhotoOwner end')
    }
}

export const deletePhotoOwner = async (context, payload ) => {
    const { idOwner, idGallery } = payload
    try {
        const { data } = await axiosClient.delete(`/fivesClub/galleryOwners/`, { params: { idOwner, idGallery} } )
        return data

    } catch (error) {
        return error.response.data
    } finally {
        consoleMsgFinally('deletePhotoOwner end')
    }
}

export async function saveOwners(context, payload) {
    const { photo } = payload
    try {
        const formData = new FormData()
        formData.append('file', photo)
        formData.append('data', JSON.stringify(payload))

        const response = await Vue.axios({
            method: 'POST',
            url: `/fivesClub/getOwners/`,
            data: formData
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
    } finally {
        console.log('saveOwners end')
    }
}


export const sendResetPasswordLink = async (context, payload) => {
    const { email, resendemail } = payload

    try {
        const { data } = await axiosClient(`/fivesClub/resetOwnerPassword/`, { params: { email, resendemail } })
        return data
    } catch (error) {
        return error.response.data
    } finally {
        consoleMsgFinally('sendResetPasswordLink end')
    }
}

export const tokenResetPasswordValidity = async (context, payload) => {

    try {
        const { data } = await axiosClient.post(`/fivesClub/resetOwnerPassword/`, payload)
        return data
    } catch (error) {
        // console.log(error.response.data)
        return error.response.data
    } finally {
        consoleMsgFinally('tokenResetPasswordValidity end')
    }
}

export const discardTokenResetPassword = async (context, payload) => {
    try {
        const { data } = await axiosClient.put(`/fivesClub/resetOwnerPassword/`, payload)
        return data
    } catch (error) {
        // console.log(error.response.data)
        return error.response.data
    } finally {
        consoleMsgFinally('discardTokenResetPassword end')
    }
}

