import Vue from 'vue'
import VueRouter from 'vue-router'
// Routes
import authRouter from '@/modules/auth/router'
import ownersRouter from '@/modules/owners/router'
import marketRouter from '@/modules/market/router'
import myPropertyRouter from '@/modules/myProperty/router'


Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'home' } },
    ...authRouter,
    ...ownersRouter,
    ...marketRouter,
    ...myPropertyRouter,
    {
      path: '*',
      redirect: 'not-found',
    },
  ],
})


// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
