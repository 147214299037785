export function filteredProducts( state ) {
    let products = state.products
    const orderBY = state.orderBy
    //con esto filtro los resultado por el name del producto, no es case sensitive
    if( state.filter.query.length > 1 ){
        products = products.filter(
            product => product.name.toLowerCase().includes(state.filter.query.toLowerCase()) ||
                       product.metatag.toLowerCase().includes(state.filter.query.toLowerCase())            
        )        
    }
    if( orderBY == "alfabetico" ){
        return products.sort((a,b) => a.name > b.name ? 1 : -1)
    } else if( orderBY == "price-asc" ){
        return products.sort((a, b) => parseFloat(a.detail[0].saleprice)-parseFloat(b.detail[0].saleprice))
    } else if( orderBY == "price-desc"){
        return products.sort((a, b) => parseFloat(b.detail[0].saleprice)-parseFloat(a.detail[0].saleprice))
    } else {
        return products        
    }
}


export function totalDiscountOnAllProducts(state) {
    let desc = 0
    state.cart.forEach((product) => {
        const decimalDiscount = parseFloat(product.discountApplied) / 100
        desc += (parseFloat(product.originalSalePrice) * parseFloat(decimalDiscount) * parseFloat(product.qty))
    })
    return (Math.round(desc * 100) / 100).toFixed(2)
}

export function totalDiscountOrder(state) {
    let sum = 0
    const decimalDiscount = parseFloat(state.breakdown.discountApplied) / 100
    state.cart.forEach((product) => {
        sum += (parseFloat(product.saleprice) * parseFloat(product.qty))
    })
    return (Math.round(sum * decimalDiscount * 100) / 100).toFixed(2)
}
