<template>

<svg
  data-v-4c034fe6=""
  xmlns="http://www.w3.org/2000/svg"
  width="16px"
  height="16px"
  viewBox="0 0 24 24"
  fill="none"
  stroke="currentColor"
  stroke-width="2"
  stroke-linecap="round"
  stroke-linejoin="round"
  class="icon-svg feather feather-shuffle"><polyline
  data-v-4c034fe6=""
  points="16 3 21 3 21 8"></polyline><line
  data-v-4c034fe6=""
  x1="4"
  y1="20"
  x2="21"
  y2="3"></line><polyline
  data-v-4c034fe6=""
  points="21 16 21 21 16 21"></polyline><line
  data-v-4c034fe6=""
  x1="15"
  y1="15"
  x2="21"
  y2="21"></line><line
  data-v-4c034fe6=""
  x1="4"
  y1="4"
  x2="9"
  y2="9"></line></svg>

</template>

<script>
export default {
name: "ShuffleIcon"
}
</script>