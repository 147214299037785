import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUser, faCartPlus, faLink, faInfoCircle, faClose, faHeart, faListDots, faGear, faRightFromBracket, faRightToBracket, faPlus, faSave, faBriefcase, faPaperPlane, faMoon, faSun, faFile, faBars,faPhone, faEnvelope, faComment, faVideoCamera, faEdit, faImage, faTrash, faCircle, faExternalLink, faArrowRight, faUndo, faFilePdf} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faUser)
library.add(faCartPlus)
library.add(faLink)
library.add(faInfoCircle)
library.add(faClose)
library.add(faHeart)
library.add(faListDots)
library.add(faGear)
library.add(faRightFromBracket)
library.add(faRightToBracket)
library.add(faPlus)
library.add(faSave)
library.add(faBriefcase)
library.add(faPaperPlane)
library.add(faMoon)
library.add(faSun)
library.add(faFile)
library.add(faBars)
library.add(faPhone)
library.add(faEnvelope)
library.add(faComment)
library.add(faVideoCamera)
library.add(faEdit)
library.add(faImage)
library.add(faTrash)
library.add(faCircle)
library.add(faExternalLink)
library.add(faArrowRight)
library.add(faUndo)
library.add(faFilePdf)

Vue.component('font-awesome-icon', FontAwesomeIcon)