import { isAuthenticatedGuard } from '../../auth/router/auth-guard'

export default [          
    {
        path: '/things-to-do',
        name: 'things-to-do',
        // component: () => import('@/modules/market/views/Experiences'),    
        component: () => import('@/modules/owners/views/ComingSoon'),                

        beforeEnter: isAuthenticatedGuard,        
        meta:{
            contentClass: 'ecommerce-application',
        },           
    },
    {
        path: '/things-to-do/product/:slug',
        name: 'product-details',
        // component: () => import('@/modules/market/views/DetailExperience'),
        component: () => import('@/modules/owners/views/ComingSoon'),                

        beforeEnter: isAuthenticatedGuard,        
        meta: {
            requiresAuth: true,
        },
        props: ( route ) => {
            return {
                slug: route.params.slug
            }
        },        
    },
    {
        path: '/things-to-do/cart',
        name: 'order-summary',
        // component: () => import('@/modules/market/views/Cart'),     
        component: () => import('@/modules/owners/views/ComingSoon'),                
        beforeEnter: isAuthenticatedGuard, 
        meta:{
            contentClass: 'ecommerce-application',
        }       
    },
    {
        path: '/things-to-do/checkout',
        name: 'checkout',
        // component: () => import('@/modules/market/views/Checkout.vue'),         
        component: () => import('@/modules/owners/views/ComingSoon'),                

        beforeEnter: isAuthenticatedGuard,  
        meta:{
            contentClass: 'ecommerce-application',
        }              
    },
      
]

