import { mapState, mapMutations } from 'vuex'
import { version } from '../../../../package'

export const auth = {
  computed:{
    ...mapState('auth', ['user','myContracts','userAdmin']),
  },
  methods: {
    ...mapMutations('auth', ['logout','logoutAdmin']),
    ...mapMutations('owners', ['setDataOwnerLogout']),
    ...mapMutations('market', ['logoutMarket']),

    normalLogout() {
      this.logout()
      this.logoutAdmin()
      this.setDataOwnerLogout()
      // this.logoutMarket()
      localStorage.clear() // clear everything
      localStorage.setItem('appVersion', version)

      window.location.reload()
    },
    checkVersion(){
      const localVersion = localStorage.getItem('appVersion')
      let reload = false
      if(!localVersion){
        reload = true
      }
      if( localVersion ){
        if( localVersion !== version ){
          reload = true
        }
      }

      return reload
    }
  }
}

