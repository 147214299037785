import { consoleMsgFinally } from '@/helpers/helpers'
import axiosClient from '@/libs/axios'

// Access Control
export const fetchControlAccessType = async (context, payload) => {
    try {
        const { data } = await axiosClient(`/fivesClub/getControlAccessType/`, { params: { ...payload } })
        const { data:datos } = data
        return datos
    } catch (error) {
        console.warn(error?.response?.data) //false cuando es un bad request, el message error error.response.data
        return []
    } finally {
        consoleMsgFinally('fetchControlAccessType end')
    }
}


export const fetchControlAccess = async (context, payload) => {
    try {
        const { data } = await axiosClient(`/fivesClub/getControlAccess/`, { params: { ...payload } })
        const { data:datos } = data
        return datos
    } catch (error) {
        console.warn(error?.response?.data) //false cuando es un bad request, el message error error.response.data
        return []
    } finally {
        consoleMsgFinally('fetchControlAccess end')
    }
}

export const saveControlAccess = async (context, payload) => {

    try {
        const { status } = await axiosClient.post(`/fivesClub/getControlAccess/`, payload )
        return status
    } catch (error) {
        console.error(error, error?.response?.data)
        return false
    } finally {
        consoleMsgFinally('saveControlAccess end')
    }
}

export const updateControlAccess = async (context, payload) => {
    try {
        const { status } = await axiosClient.put(`/fivesClub/getControlAccess/`, payload )
        return status
    } catch (error) {
        console.error(error, error?.response?.data)
        return false
    } finally {
        consoleMsgFinally('updateControlAccess end')
    }
}

export const deleteControlAccess = async (context, payload ) => {
    try {
        const { status } = await axiosClient.delete(`/fivesClub/getControlAccess/`, { params: { ...payload } } )
        return status
    } catch (error) {
        console.error(error, error?.response?.data)
        return false
    } finally {
        consoleMsgFinally('deleteControlAccess end')
    }
}

// Maintenance Requests
export const fetchMaintenanceRequests = async (context, payload) => {
    try {
        const { data } = await axiosClient(`/fivesClub/getMaintenanceRequests/`, { params: { ...payload } })
        const { data:datos } = data
        return datos
    } catch (error) {
        console.warn(error?.response?.data) //false cuando es un bad request, el message error error.response.data
        return []
    } finally {
        consoleMsgFinally('fetchMaintenanceRequests end')
    }
}

export const saveMaintenanceRequest = async (context, payload) => {
    try {
        const { files, images } = payload
        const formData = new FormData()
        files.forEach(file => {
            formData.append('files', file)
        })
        images.forEach(img => {
            formData.append('images', img)
        })

        formData.append('data', JSON.stringify(payload))
        // console.log(formData)

        const { status } = await axiosClient.post(`/fivesClub/getMaintenanceRequests/`, formData )
        return status
    } catch (error) {
        console.error(error, error?.response?.data)
        return false
    } finally {
        consoleMsgFinally('saveMaintenanceRequest end')
    }
}

export const updateMaintenanceRequest = async (context, payload) => {
    try {
        const { files, images } = payload
        const formData = new FormData()
        files.forEach(file => {
            formData.append('files', file)
        })
        images.forEach(img => {
            formData.append('images', img)
        })

        formData.append('data', JSON.stringify(payload))
        // console.log(formData)

        const { status } = await axiosClient.put(`/fivesClub/getMaintenanceRequests/`, formData )
        return status
    } catch (error) {
        console.error(error, error?.response?.data)
        return false
    } finally {
        consoleMsgFinally('updateMaintenanceRequest end')
    }
}

export async function cancelMaintenanceRequest(context,datos) {
    try {
        const { status } = await axiosClient.put(`/fivesClub/setMaintenanceRequestResolution/`, datos )
        return status
    } catch (error) {
        console.log(error)
        return false
    } finally {
        console.log('cancelMaintenanceRequest end')
    }
}

// Bookings

export const fetchOwnerBookingsLite = async (context, payload) => {
    try {
        const { data } = await axiosClient(`/fivesClub/getOwnerBookingFromOwner/`, { params: { ...payload } })
        const { data:datos } = data
        return datos
    } catch (error) {
        console.warn(error?.response?.data) //false cuando es un bad request, el message error error.response.data
        return []
    } finally {
        consoleMsgFinally('fetchOwnerBookingsLite end')
    }
}
