require('./feather')

require('./boostrap-vue')
require('./portal-vue')
require('./clipboard')
require('./toastification')
require('./sweet-alerts')
require('./vue-select')
require('./vee-validate')
require('./tour')
// require('./vue-carousel')

// require('./vue-json-excel')
require('./fontawesome')
