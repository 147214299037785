export function contractSelected( state ) {
    let selectedContract = null
    
    const contracts = state.myContracts

    if( contracts ){
        selectedContract = contracts.contractSelected
    }

    return selectedContract
}
