import store from '../../../store'

export const isAuthenticatedGuard = (to, from, next) => {

    const { isLogged } =  store.state.auth
    if ( isLogged  ) next()
    else next( {name: 'auth-login'} )
}

export const requiresParameterAuth = async (to, from, next) => {

    const { isLogged, showModules } =  store.state.auth
    if (isLogged) {
        if ( showModules?.myProperty ) next()
        else next( {name: 'home'} )
    } else next( {name: 'auth-login'} )
}

export const promotionsRequiresParameterAuth = async (to, from, next) => {

    const { isLogged, showModules } =  store?.state?.auth
    if (isLogged) {
        if ( showModules?.promotions ) next()
        else next( {name: 'home'} )
    } else next( {name: 'auth-login'} )
}

export const onlyOutOfPoolAuth = async (to, from, next) => {

    const { isLogged, myContracts } =  store.state.auth
    const isOutOfPool = myContracts?.contractSelected?.membershipcode === 'CLASSIC'

    if (isLogged) {
        if ( isOutOfPool ) next()
        else next( {name: 'home'} )
    } else next( {name: 'auth-login'} )
}

export const suscriptionsModulesAuth = async (to, from, next) => {

    const { isLogged, myContracts, showModules } =  store?.state?.auth
    const canSusbcribe = myContracts?.contractSelected?.canSusbcribe
    if (isLogged) {
        if ( showModules?.exchanges && canSusbcribe ) next()
        else next( {name: 'home'} )
    } else next( {name: 'auth-login'} )
}
