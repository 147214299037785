<template>
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 7.5L7 9.5L10.5 5.5M10.5 0.5H2.5C1.94772 0.5 1.5 0.947716 1.5 1.5V13.5C1.5 14.0523 1.94772 14.5 2.5 14.5H12.5C13.0523 14.5 13.5 14.0523 13.5 13.5V3.5L10.5 0.5Z" stroke="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: "FilesIcon"
}
</script>