import { requiresParameterAuth, onlyOutOfPoolAuth } from "@/modules/auth/router/auth-guard"

export default [
    {
        path: '/my-property/',
        name: 'my-property',
        component: () => import('@/modules/myProperty/views/MyProperty'),
        beforeEnter: requiresParameterAuth,
        meta: {
            layout: 'AdminLayout',
        },
    },
    // Control Access
    {
        path: '/my-property/control-access/create/',
        name: 'create-access-control',
        component: () => import('@/modules/myProperty/views/CreateAccess'),
        beforeEnter: requiresParameterAuth,
        meta: {
            layout: 'AdminLayout',
            requiresAuth: true,
        }
    },
    {
        path: '/my-property/control-access/edit/:id',
        name: 'edit-access-control',
        component: () => import('@/modules/myProperty/views/EditAccess'),
        beforeEnter: requiresParameterAuth,
        meta: {
            layout: 'AdminLayout',
            requiresAuth: true,
        }
    },
    {
        path: '/my-property/control-access/:id',
        name: 'access-control',
        component: () => import('@/modules/myProperty/views/AccessControl'),
        beforeEnter: requiresParameterAuth,
        meta: {
            layout: 'AdminLayout',
            requiresAuth: true,
        }
    },
    // Maintenance requests
    {
        path: '/my-property/maintenance-request/create/',
        name: 'create-maintenance-request',
        component: () => import('@/modules/myProperty/views/CreateRequest'),
        beforeEnter: onlyOutOfPoolAuth,
        meta: {
            layout: 'AdminLayout',
            requiresAuth: true,
        }
    },
    {
        path: '/my-property/maintenance-request/edit/:id',
        name: 'edit-maintenance-request',
        component: () => import('@/modules/myProperty/views/EditRequest'),
        beforeEnter: onlyOutOfPoolAuth,
        meta: {
            layout: 'AdminLayout',
            requiresAuth: true,
        }
    },
    {
        path: '/my-property/maintenance-request/:id',
        name: 'maintenance-request',
        component: () => import('@/modules/myProperty/views/MaintenanceRequest'),
        beforeEnter: onlyOutOfPoolAuth,
        meta: {
            layout: 'AdminLayout',
            requiresAuth: true,
        }
    },
]