<template>
    <svg width="18" height="18" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 5.5H15M0 1.5H15M0 9.5H15M0 13.5H15" stroke="currentColor"/>
    </svg> 
</template>

<script>
export default {
  name: "MenuIcon"
}
</script>