<template>    
    <b-modal
		size="md"
		id="admin-restric-modal"
		title="Aceptar"
		ok-only
		centered
		no-close-on-backdrop
		hide-header
        modal-bg-variant="custom-opacity"
	>
		<div class="modal-container-restrict">
			<div v-if="langLocale === 'en' ">
				<p>
					Sorry, you are not allowed to do this action.
				</p>
				<p>
					Please request to the Owner do it by itself.
				</p>
			</div>
			<div v-else>
				<p>
					Lo sentimos, no estás autorizado para realizar esta acción.
				</p>
				<p>
					Por favor, solicita al Propietario lo haga por si mismo.
				</p>
			</div>
		</div>
	</b-modal>
</template>

<script>

import { mapActions, mapState, mapGetters  } from 'vuex'

import { utils } from '@/modules/owners/mixins/utils'

export default {
	mixins: [ utils ],	
	computed: {
		...mapState("auth", ["user"]),	
		...mapGetters('auth',['contractSelected']),
        langLocale(){
            return this.$i18n.locale
        }
	},
 
}
</script>
<style>

.modal-container-restrict {
	margin-top: 1rem;
	color: #0e0d0d;
    /* border: 6px solid #6b6a6a; */
    /* border-radius: 0!important; */
    /* box-shadow: 0 5px 20px 0 rgba(34, 41, 47, 0.1); */
}




/*
{ opacity: .3; background-color: rgb(0,0,0,.3); height: 100%; } modal <--cuadro gris (border) element { position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); display: block; background: #999; width: 600px; height: 300px; padding: 5px; } inner modal <--cuadro blanco element { background: #fff; display: block; width: 100%; height: 100%; padding: 10px; text-align: justify; } clase para los nombres de marcas .hlgt { font-style: italic; font-weight: bold; } */

</style>
