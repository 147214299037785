export function setScheduleControlAccess(state, accessControl) {
    state.accessControlList = accessControl
}

export function setControlAccessType(state, controlAccessType) {
    state.controlAccessType = controlAccessType
}

export function setBookingsDataLite(state, bookings) {
    state.bookings = bookings
}

export function setMaintenanceRequests(state, maintenanceRequests) {
    state.maintenanceRequestsList = maintenanceRequests
}
