<template>
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 14.5H15M5.5 12V6M9.5 12V3M13.5 12V0M1.5 9V12" stroke="#1D1929"/>
    </svg>
</template>

<script>
export default {
  name: "BarChartIcon"
}
</script>