export default {
    categories:{
        hotelServices: [],
        onlyCategories: [],        
        withSubCategories: [],
        categoryServices: [],
    },
    filter: { query: '', },
    isLoadingProducts: false,
    orderBy: 'alfabetico',    
    productDetail: null, 
    products: [],
    hotels:[],
    languages:[],
    selectedCategory: null,
    cart:[],
    currencies: [],
    breakdown: { total: 0.00, discountApplied: 0 },
    customer: {
        id: '', // id el propietario
        customeragent: '',
        customertype: 2, // 2  es propietario
        email: '',
        hotel: '',
        sihotCode: null,
        idCustomer: null,
        idUser: '',
        language: 1,
        lastname: '',
        name: '',
        paymentlink: '',
        paymentmethod: 1040, // 1040 The Fives Benefit
        paymentreference: '',
        phone: '',
        sellercanalventa: null
    },
    customerCurrency: null,
}