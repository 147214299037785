import { v4 as uuidv4 } from 'uuid'
import { find } from 'lodash'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import * as moment from 'moment'

const stringAleatorio = () => {    
    return uuidv4() 
}

const validateDataInCart = (cart) => {
    const itemFind = find(cart, { requiredFieldsFilled: false })
    if (itemFind) {
        const msg = `💡 Existe por lo menos algún elemento sin completar sus datos, asegurese de completar esa información.`
		showAlertMessage( 'Notification', 'BellIcon', msg, 'danger', 4000, 'bottom-right')             
        return true
    } else {
        return false
    }
}

const toDecimal = (stringToDecimal) => {
    return String((Math.round(stringToDecimal * 100) / 100).toFixed(2))
}

const toJson = (stringToJson) => {
    return JSON.parse(JSON.stringify(stringToJson))
}

const showAlertMessage = (title = 'Ok', icon = 'BellIcon', text, variant = 'success', timeout = '3000', position = 'bottom-right') => {
    Vue.$toast({
        component: ToastificationContent,
        props: { title, icon, text, variant },
    }, { timeout, position })
}

const consoleMsgFinally = (message) => {
    if (process.env.NODE_ENV == 'development' ) {
        console.log(`${message}`)
    }
}

const formatContracts = (contracts) => {
    contracts.forEach( (contract, index ) => {
        // THE FIVES BEACH | RESIDENCE 312 | PREMIUM
        const { resortsname, housingnumber, membershipcode } = contract 
        const hotelName = resortsname ? resortsname.toUpperCase() : ''
        const membership = membershipcode ? membershipcode.toUpperCase() : ''
        contract.customInfoLong = `${hotelName} | RESIDENCE ${housingnumber} | ${membership}` 
    })
    return contracts
}
const createMenuContracts = (contracts, numberHouse = null) => {
    let menu = {
        contractSelected: null,
        contracts: [],
        benefits: [],
    }

    let currentContract

    if (!!numberHouse){
        currentContract = contracts.find( c => c.housingnumber === numberHouse )
        if (!currentContract) currentContract = {...contracts[0]}
    } else currentContract = {...contracts[0] }

    menu.contractSelected = currentContract
    menu.contracts = contracts
    return menu
}

const formatBenefits = ( benefits ) => {
    benefits.forEach( (benefit, index) => {
        benefit.isSelected = index === 0 && true                
    })
    return benefits
}

const formatArrayFileDocuments = ( files ) => { 
    files.forEach( element => {                
        element.extension = element.fileRoute.split('.').pop().toLowerCase()
        element.lastRoute = element.fileRoute.split('/').pop()
        element.tipeFile = typeFile(element.extension)
    })
    return files
}

const typeFile = ( extension ) => {

    let typeFile = 'default file'

    const images = ['jpg', 'jpeg', 'png', 'gif']
    const pdf = ['pdf']
    const video = ['avi', 'wmv', 'mp4', 'webm', 'avi']
    const audio = ['mp3', 'ogg', 'flv', 'flac', 'wav', 'wma']
    const word = ['doc', 'docx']
    const excel = ['xls', 'xlsx']
    const ppt = ['ppt', 'pptx']
    
    if(images.includes(extension)){
        typeFile = 'imagen'
    }

    if(pdf.includes(extension)){
        typeFile = 'pdf'
    }
     
    if(video.includes(extension)){
        typeFile = 'video'
    }

    if(audio.includes(extension)){
        typeFile = 'audio'
    }
     
    if(word.includes(extension)){
        typeFile = 'word'
    }

    if(excel.includes(extension)){
        typeFile = 'excel'
    }
     
    if(ppt.includes(extension)){
        typeFile = 'ppt'
    }

    return typeFile
}

const splitArrayFileDocuments = ( documents ) => {      
    const half = Math.ceil(documents.length / 2)    
    const firstHalfFiles = documents.slice(0, half)
    const idFilesMap = firstHalfFiles.map(f => f.id)        
    const secondHalfFiles = documents.filter(({id}) => !idFilesMap.includes(id))    
    return { firstHalfFiles, secondHalfFiles }
}

const camposRequeridosPorLLenar = (product) => {
    let camposFaltantes = []
    const { operationdate } = product
    
    if (!operationdate ) {
        camposFaltantes.push({ key: stringAleatorio(), campo: "Date" })
    }
          
    return camposFaltantes
}

const orderBookingsByDateInAsc = (bookings) => {
    return bookings.sort(function(a,b){       
        return new Date(b.datein) - new Date(a.datein)
    })
}

const formatDate = ({ date, language, format, toFormat }) => {
    const languageName = language === 'English' ? 'en' : 'es'; // El usuario conectado trae un idioma
    const dateFormat = format ? moment(date, format) : moment(date);

    return dateFormat.locale(languageName).format(toFormat || (languageName === 'es' ? 'ddd DD/MM/YYYY' : 'ddd, MM/DD/YYYY'));
};

const formatTime = (payload = {time, language, format, toFormat}) => {
    const {time, language, format, toFormat} = payload
    const languageName = language === 'English' ? 'en' : 'es' // el usuario conectado trae un idioma

    const toDate = format ? moment(time, format) : moment(time)
    const formatDate = toFormat || "hh:mm A"

    return toDate.locale(languageName).format(formatDate)
}

function toCurrency(amount = 0, currency = "USD") {
    let local

    if (currency && currency == "MXN") local = 'es-MX'
    else if (!currency || currency == "USD") local = 'en-US'

    const dineros = Intl.NumberFormat(local, {
        style: "currency",
        currency: currency,
        useGrouping: true,
    })

    return dineros.format(amount)
}

const splitTime =(time) => {
    if (time) {
        const hasSeason = time.match(/am/g) || time.match(/pm/g)
        const hasSeparator = time.match(/[:]/g)
        if (hasSeason?.length){
            time = time.replace(/[a-zA-Z]/g, '')
            if (!hasSeparator) {
                time = time.match(/..?/g)
                time = formatTime({time: `${time[0]}:${time[1]} `, format:"HH:mm:ss"})
            } else time = formatTime({time: `${time} ${hasSeason[0]}`, format:"hh:mm A"})
            return time
        }
        if(hasSeparator){
            time = formatTime({time: `${time}:00`, format:"HH:mm:ss"})
            return time
        }
        else {
            time = time.match(/..?/g)
            time = formatTime({time: `${time[0]}:${time[1]}:00`, format:"HH:mm:ss"})
            return time
        }
    }
}

const filterFilesBySize = (files, size) => {
    const filteredBySize = files.filter( file => file.size < size)
    if (filteredBySize.length == files.length) return filteredBySize
    else  {
        // showAlertMessage( 'Archivos inválidos', 'InfoIcon', `No puedes subir archivos con un tamaño a los establecidos: ${parseFloat(size/(1024 * 1024))}MB`, 'danger', 4000, 'bottom-right')
        return  false
    }
}

const getLocalFilePath = (file) => {
    const url = URL.createObjectURL(file);
    URL.revokeObjectURL(file) // free memory
    return url
}

const makeTime = async (time = 600) => {
    const myPromise = await new Promise((resolve, reject) => {
      setTimeout(() => {
        console.log('making time')
        resolve()
      }, time);
    })
    return myPromise
  }

const getDateDiff = (time, type) => {
    const {d1, d2 = new Date()} = time

    if (type=='Months') {
        const d1Y = d1.getFullYear()
        const d2Y = d2.getFullYear()
        const d1M = d1.getMonth()
        const d2M = d2.getMonth()

        return (d2M+12*d2Y)-(d1M+12*d1Y)
    }

    if (type=='Year') {
        return d2.getFullYear()-d1.getFullYear()
    }

    else {
        const t2 = d2.getTime()
        const t1 = d1.getTime()
        const diff = t2-t1

        if (type=='Miliseconds') {
            return diff % 1000
        }
        if (type=='Seconds') {
            return Math.floor(diff / 1000) % 60
        }
        if (type=='Minutes') {
            return Math.floor(diff / 1000 / 60) % 60
        }
        if (type=='Hours') {
            return Math.floor(diff / 1000 / 60 / 60)
        }
        if (type=='Days') {
            return Math.floor(diff / (24*3600*1000))
        }
        if (type=='Weeks') {
            return parseInt(diff / (24*3600*1000*7))
        }
    }
}


export {
  validateDataInCart,
  stringAleatorio,
  toDecimal,
  toJson,
  showAlertMessage,
  consoleMsgFinally,
  createMenuContracts,
  formatBenefits,
  formatContracts,
  formatArrayFileDocuments,
  splitArrayFileDocuments,
  camposRequeridosPorLLenar,
  orderBookingsByDateInAsc,
  formatDate,
  formatTime,
  toCurrency,
  splitTime,
  filterFilesBySize,
  getLocalFilePath,
  makeTime,
  getDateDiff
}