<template>            
    <header class="header " :class="sidebarIsOpen ? 'sidebar-is-open': ''" >
        <div class="container-header myContainer">
            <div>
                <svg class="menu-icon" width="26" height="26" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" id="sidebarBtnOpen" @click="openSidebar">
                    <path d="M0 5.5H15M0 1.5H15M0 9.5H15M0 13.5H15" stroke="currentColor"/>
                </svg>
            </div>
            <div class="head-residence">
                <h1>{{ contractSelected ? contractSelected.customInfoLong : '...' }}</h1>
            </div>
            <div class="head-right">
                <h2 variant="success" >{{currentPath.display}}</h2>
                <div class="icons-right">
                    <svg  viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" @click="skin = isDark ? 'light' : 'dark'" v-show="!sidebarIsOpen">
                        <path d="M1.66077 11.3619C2.09296 11.4524 2.54093 11.5 3.00002 11.5C6.58987 11.5 9.50002 8.58984 9.50002 4.99999C9.50002 3.25479 8.81224 1.67024 7.69295 0.502594C11.4697 0.604809 14.5 3.69852 14.5 7.49999C14.5 11.366 11.366 14.5 7.49999 14.5C5.06138 14.5 2.91401 13.253 1.66077 11.3619Z" stroke="currentColor" stroke-linejoin="round"/>
                    </svg>
                    <svg  viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" @click="_logout" v-show="!sidebarIsOpen">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.903382 0.903381L7.90338 0.903382V1.90338L1.90338 1.90338L1.90338 12.9034L7.90338 12.9034V13.9034L0.903381 13.9034L0.903382 0.903381ZM10.7569 4.04983L14.0966 7.38952L10.7708 10.9925L10.036 10.3142L12.2614 7.90338L3.90338 7.90338L3.90338 6.90338L12.1963 6.90338L10.0498 4.75694L10.7569 4.04983Z" fill="currentColor"/>
                    </svg>
                </div>
            </div>
        </div>
    </header> 
</template>

<script>
    import { mapState, mapMutations } from 'vuex'
    import useAppConfig from '@core/app-config/useAppConfig'
    import { auth } from '@/modules/auth/mixins/auth'
    import { computed } from 'vue'

    export default {
        mixins: [auth],
        name: "Header",
        props:{
            sidebarIsOpen: {
                type: Boolean,
                required: true,
            },
            routes:{
                type:Array,
                required: true,
            }
        },
        computed:{             
            ...mapState('auth',['userAdmin','myContracts']),                    
            currentPath(){
                const path = this.$route.name
                return this.routes.find( r => r.name === path)
            },
            contractSelected(){
                return this.myContracts?.contractSelected
            }
        },
        methods: {
            openSidebar(){
                this.$emit('open-sidebar')
            },
            _logout(){
                if(this.userAdmin){
                    this.$router.push({name: 'admin'})
                    return
                }
                this.normalLogout()
            },
        },
        setup() {
            const { skin } = useAppConfig()

            const isDark = computed(() => skin.value === 'dark')

            return { skin, isDark }
        },
    }
</script>

<style lang="scss" scoped>

$heightHeader: 5rem;

.header{    
    width: 100%;
    height: $heightHeader;   
    box-shadow: 0 2px 2px 1px rgba(167, 164, 164, 0.2);    
    display: flex;
    align-items: center;
    -webkit-transition: all 0.2s cubic-bezier(0.17, 0.67, 0.83, 0.67);
    -moz-transition: all 0.2s cubic-bezier(0.17, 0.67, 0.83, 0.67);
    transition: all 0.2s cubic-bezier(0.17, 0.67, 0.83, 0.67); 
}
.myContainer{
    margin: 1rem;
}
.container-header{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;    
}
    
.head-residence h1{
    font-size: 1rem;
    color: black;
    font-weight: 600;
}

.head-right{
    display: flex;
    flex-direction: column;
    gap: .6rem;
}

.head-right h2{
    font-size: .9rem;
    font-weight: 600;
    color: #000;
}
.icons-right{
    display: flex;
    justify-content: space-around;
}
.icons-right svg{
    cursor: pointer;
    width: 26px;
    height: 26px;
}


@media (min-width:960px) {
    .header.sidebar-is-open{
        width: calc(100% - 280px);
        left: 280px;
    }

    .header.sidebar-is-open #sidebarBtnOpen{
        display: none;        
    }
   
    .menu-icon {
        width: 20px;
        height: 20px;         
    }
    .icons-right svg{       
        width: 20px;
        height: 20px;
    }

    .head-residence h1{
        font-size: 1.3rem;
    }

    .head-right h2{
        font-size: 1.1rem;
    }
    
}
    

</style>