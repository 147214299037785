export function setUser(state, user) { //se ejecuta una vez el usaurio haga login
    state.user = user
    state.isLogged = true
}

export function setLanguageUser(state, data) { //se ejecuta una vez el usaurio haga login
    const { languageCode, languageId, languageName } = data
    state.user.languageCode = languageCode
    state.user.languageId = languageId
    state.user.languageName = languageName
}

export function logout(state) {
    state.user = null    
    state.isLogged = false
    state.myContracts = null    
    state.benefits = []    
}

export function setAdmin(state, user) {
    if( user.isAdmin ){
        state.userAdmin = user
        state.userAdmin.idOwnerSelected = null
        state.userAdmin.numberHousingSelected = null
        state.userAdmin.idHotelSelected = null
    }
}
export function SetViewModules(state, modules) {
    state.showModules = modules
}
export function setIdHotelAdmin(state, data) {
    const { idHotel } = data
    state.userAdmin.idHotelSelected = idHotel   
}

export function setIdHousingAdmin(state, data) {
    const { idHousing } = data
    state.userAdmin.numberHousingSelected = idHousing   
}

export function setIdOwnerAdmin(state, data) {
    const { idOwner } = data
    state.userAdmin.idOwnerSelected = idOwner   
}

export function logoutAdmin(state) {
    state.userAdmin =  null
}

export function setContracts(state, menu) {
    state.myContracts = menu    
}

export function setContractSelected(state, payload) {
    const { contracts, contractSelected } = payload
    state.myContracts.myContracts = contracts    
    state.myContracts.contractSelected = contractSelected                      
}

export function setBenefitsContractSelected(state, benefits) {          
    state.benefits = benefits          
}

export function setGalleryPhotosUser(state, gallery ) { //se ejecuta una vez el usaurio haga login
    state.user.gallery = gallery
}

export function setEmailReset(state, email ) { 
    state.emailReset = email
}

export function setMessageEmailReset(state, messageEmailReset = '' ) { 
    state.messageEmailReset = messageEmailReset
}

export function undoEmailReset(state) { 
    state.emailReset = null
    state.messageEmailReset = ''
}